export const deliveryReport = {
	title: "Rapports de livraison",
	description: "Suivez et analysez les livraisons de votre établissement.",
	tabs: {
		general: "Rapport général",
	},
	selectPeriod: "Sélectionnez une période",
	periodOptions: {
		today: "Aujourd'hui",
		last7days: "7 derniers jours",
		last15days: "15 derniers jours",
		last30days: "30 derniers jours",
		last90days: "90 derniers jours",
		custom: "Sélectionner une période",
	},
	generalTab: {
		export: "Exporter",
		filtersToolbar: {
			search: "Rechercher n° de commande",
			vendors: "Vendeurs",
			vendorsPlaceholder: "Sélectionner vendeurs",
			status: "Statut",
			statusPlaceholder: "Sélectionner statut",
			period: "Période",
			periodPlaceholder: "Sélectionner période",
			back: "Retour",
			apply: "Appliquer",
		},
		table: {
			columns: {
				vendor: "Vendeur",
				reference: "Commande N°",
				status: "Statut",
				date: "Date",
				orderCreatedAt: "Heure de commande",
				orderEndAt: "Commande terminée",
				value: "Total",
				viewMore: "Voir plus",
			},
			summary: "Total de la période",
			seeMore: "Voir plus de détails",
		},
		vendorType: {
			rappi: "Rappi",
			coala: "Coala",
			ifood: "iFood",
			ubereats: "Ubereats",
		},
		orderStatus: {
			cancelled: "Annulée",
			concluded: "Terminée",
			confirmed: "Confirmée",
			dispatched: "Expédiée",
			integrated: "Intégrée",
			pendingPayment: "Paiement en attente",
			pickupAreaAssigned: "Client sur place",
			placed: "Créée",
			ready: "Prête",
			scheduled: "Programmée",
		},
		orderDetailsDrawer: {
			orderRange:
				"Commande passée le {{startDate}} à {{startHour}}<br>Terminée à {{endHour}}",
			order: "Commande",
			paymentDetails: "DÉTAILS DU PAIEMENT",
			paymentMethod: "Mode de paiement",
			subTotal: "Sous-total de la vente",
			discountAndImpost: "Frais et remises",
			totalReceive: "Total à recevoir",
			discountValue: "{{value}} - Coupon de réduction",
			discountValueDeliveryFee: "{{value}} - Frais de livraison",
			discountValueDeliveryFeeMarketplace: "{{value}} - Frais de livraison Marketplace",
			discountValueMarketplace: "{{value}} - Marketplace",
			paymentMethodType: {
				creditCardInPlace: "Carte de crédit",
				creditCardOnline: "Carte de crédit",
				debitCardOnline: "Carte de débit",
				debitCardInPlace: "Carte de débit",
				cash: "Espèces",
				voucherInPlace: "Bon d'achat",
				ame: "Ame",
				pix: "Pix",
			},
		},
	},
	orderItemsTable: {
		columns: {
			count: "Qté.",
			name: "Article",
			baseUnitPrice: "Prix unitaire",
			totalUnitPrice: "Sous-total",
		},
		summary: "Sous-total",
		totalReceive: "TOTAL À RECEVOIR",
	},
};
