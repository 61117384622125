/* eslint-disable */
import { SdkgenError, SdkgenHttpClient } from "@sdkgen/browser-runtime";

export type AdditionalFieldType = "number" | "text" | "select" | "date";

export type MountableSectionPricing = "sum" | "average" | "higher";

export type PaymentMethod = "creditCardInPlace" | "debitCardOnline" | "debitCardInPlace" | "cash" | "creditCardOnline" | "voucherInPlace" | "ame" | "pix";

export type Vendor = "rappi" | "coala" | "ifood" | "ubereats";

export type OrderStatus = "placed" | "scheduled" | "integrated" | "confirmed" | "dispatched" | "concluded" | "cancelled" | "ready" | "pendingPayment" | "pickupAreaAssigned";

export type DeliveryType = "dineIn" | "pickUp" | "delivery" | "deliveryByPartner" | "deliveryByRestaurant" | "deliveryByMarketplace" | "onlineFicha" | "onlineTab" | "viewMenu";

export type DeliveryChannel = "phone" | "whatsapp" | "marketplace" | "whitelabel";

export type DeliveryOrderCustomerDocumentType = "cpf" | "phone" | "unknown";

export type DeliveryOrderItemsSubItemsPricing = "sum" | "average" | "higher";

export type ExternalStoreType = "iFood" | "rappi";

export type TimeInterval = "weekly" | "monthly" | "yearly";

export type StableBaseStorePlanStatus = "active" | "suspended" | "inactive";

export type AgreementDetailsType = "agreement" | "trial" | "partner";

export type MenuType = "delivery" | "table" | "integratedDelivery";

export type MenuWorkingPeriodDayOfWeek = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun" | "hol";

export type TypeAccount = "conta_corrente" | "conta_poupanca" | "conta_corrente_conjunta" | "conta_poupanca_conjunta";

export type TypeDocument = "cpf" | "cnpj";

export type RecipientAcquirerResponseType = "error" | "success";

export type GetCombinedOrdersReportOrderBy = "reference" | "date" | "status" | "value";

export type GetCombinedOrdersReportOrderDirection = "asc" | "desc";

export type CombinedOrderReportSubItemDtoPricing = "sum" | "average" | "higher";

export type TrialPlanPeriodPayInterval = "weekly" | "monthly" | "yearly";

export interface StoreAdditionalFieldsOptions {
    id: string | null
    label: string
}

export interface StoreAdditionalFields {
    id: string | null
    required: boolean
    slug: string
    label: string
    type: AdditionalFieldType
    options: StoreAdditionalFieldsOptions[] | null
}

export interface StoreAdditionalFieldAnswerValues {
    id: string
    label: string
    value: string
}

export interface StoreAdditionalFieldAnswer {
    id: string
    value: string
}

export interface BaseAddress {
    streetName: string
    city: string
    country: string
    state: string
    neighborhood: string
    postalCode: string
    complement: string | null
}

export interface Address {
    streetName: string
    city: string
    country: string
    state: string
    neighborhood: string
    postalCode: string
    complement: string | null
    formattedAddress: string
    streetNumber: string
}

export interface BaseProduct {
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
}

export interface BaseSectionProduct {
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    price: number
    maxCount: number | null
    order: number
    id: string
}

export interface MountableSection {
    id: string
    title: string
    min: number
    max: number
    pricing: MountableSectionPricing
    order: number
    products: BaseSectionProduct[]
}

export interface BaseProductWithSections {
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    mountableSections: MountableSection[]
}

export interface Product {
    id: string
    isActive: boolean
    isMountable: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
}

export interface CompleteProduct {
    id: string
    isActive: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    mountableSections: MountableSection[]
}

export interface CompleteProductWithMenusMenus {
    id: string
    name: string
}

export interface CompleteProductWithMenus {
    id: string
    isActive: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    mountableSections: MountableSection[]
    menus: CompleteProductWithMenusMenus[]
}

export interface MenuProduct {
    price: number
    minProductValue: number | null
    id: string
    isActive: boolean
    isMountable: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
}

export interface MenuCategoryWithProductProducts {
    id: string
    isActive: boolean
    isMountable: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    minProductValue: number | null
    price: number
}

export interface MenuCategoryWithProduct {
    id: string
    name: string
    parentId: string | null
    parentName: string | null
    order: number
    categoryImageUrl: string | null
    parentImageUrl: string | null
    products: MenuCategoryWithProductProducts[]
}

export interface CompleteMenuProduct {
    price: number
    id: string
    isActive: boolean
    imageUrl: string | null
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    mountableSections: MountableSection[]
}

export interface MultipleProducts {
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
    image: ArrayBuffer | null
}

export interface BaseStoreAuth {
    storeId: string
    authToken: string
}

export interface BasePartnerAuth {
    partnerId: string
    authToken: string
}

export interface TemporaryToken {
    expiresAt: Date
    token: string
    storeId: string
}

export interface DeliveryOrderCustomer {
    name: string | null
    email: string | null
    phone: string | null
    isWhatsapp: boolean | null
    documentNumber: string | null
    documentType: DeliveryOrderCustomerDocumentType | null
    address: Address
}

export interface DeliveryOrderStore {
    id: string
    name: string
    address: Address
}

export interface DeliveryOrderMenu {
    id: string
    name: string
}

export interface DeliveryOrderPayment {
    value: number
    method: PaymentMethod
    brand: string | null
    change: number | null
    partnerPaymentId: string | null
}

export interface DeliveryOrderItemsSubItems {
    id: string
    sku: string
    name: string
    sectionId: string | null
    sectionName: string | null
    pricing: DeliveryOrderItemsSubItemsPricing
    price: number
    count: number
}

export interface DeliveryOrderItems {
    id: string
    sku: string
    name: string
    count: number
    baseUnitPrice: number
    totalUnitPrice: number
    obs: string | null
    subItems: DeliveryOrderItemsSubItems[]
}

export interface DeliveryOrder {
    id: string
    reference: number
    obs: string | null
    date: Date
    customer: DeliveryOrderCustomer
    value: number
    discountValue: number
    discountValueMarketplace: number | null
    discountValueDeliveryFee: number | null
    discountValueDeliveryFeeMarketplace: number | null
    subTotal: number
    deliveryFee: number
    tip: number
    channel: DeliveryChannel
    status: OrderStatus
    vendor: Vendor
    deliveryType: DeliveryType
    store: DeliveryOrderStore
    menu: DeliveryOrderMenu | null
    payment: DeliveryOrderPayment | null
    items: DeliveryOrderItems[]
    additionalFields: StoreAdditionalFieldAnswerValues[] | null
    externalReference: string | null
    cancellationReason: string | null
    cancellationObs: string | null
    isPostPaid: boolean
}

export interface BaseStoreDeliveryFeeRadius {
    value: number
    perKm: number
}

export interface EditStoreDeliveryFeeRadius {
    value: number
    perKm: number
    id: string | null
    storeId: string
}

export interface StoreDeliveryFeeRadius {
    value: number
    perKm: number
    id: string
    storeId: string
}

export interface BaseStoreBasic {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
}

export interface BaseStoreV1Deprecated {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    attendanceRadius: number | null
    deliveryFeePerKm: number | null
    fixedDeliveryFee: number | null
    deliveryType: DeliveryType[] | null
}

export interface StableStoreBase {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    attendanceRadius: number
    deliveryFeePerKm: number | null
    fixedDeliveryFee: number | null
    deliveryType: DeliveryType[]
    logoUrl: string | null
}

export interface BaseStoreV2 {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    attendanceRadius: number
    deliveryFeePerKm: number | null
    fixedDeliveryFee: number | null
    deliveryType: DeliveryType[]
    logoUrl: string | null
    deliveryFeeRadius: BaseStoreDeliveryFeeRadius[] | null
    deliveryAvgWaitTime: number
    dineInAvgWaitTime: number
}

export interface StoreV1Deprecated {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    id: string
    logoUrl: string | null
    attendanceRadius: number
    deliveryFeePerKm: number
    fixedDeliveryFee: number
    isManualMenu: boolean
    isOrganization: boolean
    additonalFields: StoreAdditionalFields[] | null
    deliveryType: DeliveryType[] | null
}

export interface StoreBaseForStore {
    name: string
    fantasyName: string | null
    address: Address | null
    attendanceRadius: number
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    phone: string | null
    deliveryType: DeliveryType[] | null
    whatsapp: string | null
    bannerUrl: string | null
}

export interface StoreV2 {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    attendanceRadius: number
    deliveryFeePerKm: number | null
    fixedDeliveryFee: number | null
    deliveryType: DeliveryType[]
    logoUrl: string | null
    id: string
    deliveryFeeRadius: StoreDeliveryFeeRadius[] | null
    createdAt: Date | null
    deliveryAvgWaitTime: number
    dineInAvgWaitTime: number
}

export interface CompletePaymentMethod {
    method: PaymentMethod
    brand: string | null
}

export interface StoreBySlug {
    storeId: string
    name: string
    slug: string
}

export interface PartnerDetails {
    id: string
    name: string
    email: string | null
    primaryColor: string | null
    secondaryColor: string | null
    logoUrl: string | null
    poweredByUrl: string | null
    url: string | null
}

export interface StableBasePlan {
    name: string
    description: string
    typePayInterval: TimeInterval
    termsOfUse: string | null
    paymentLink: string | null
    partnerId: string | null
}

export interface BasePlan {
    name: string
    description: string
    typePayInterval: TimeInterval
    termsOfUse: string | null
    paymentLink: string | null
    partnerId: string | null
    value: number
    isActive: boolean
    isAddon: boolean
    expiresAt: Date
    childrenIds: string[] | null
}

export interface StableBaseStorePlan {
    contractedAmount: number
    payInterval: TimeInterval
    startAgreementAt: Date
    endAgreementAt: Date
    status: StableBaseStorePlanStatus
}

export interface AgreementDetails {
    contractedAmount: number
    payInterval: TimeInterval
    startAgreementAt: Date
    endAgreementAt: Date
    status: StableBaseStorePlanStatus
    type: AgreementDetailsType
    id: string
}

export interface BaseStorePlan {
    contractedAmount: number
    payInterval: TimeInterval
    startAgreementAt: Date
    endAgreementAt: Date
    status: StableBaseStorePlanStatus
    planId: string
    storeId: string
    statusReason: string | null
}

export interface BaseFeature {
    name: string
    slug: string
    description: string | null
}

export interface Feature {
    name: string
    slug: string
    description: string | null
    id: string
}

export interface Menu {
    id: string
    name: string
    isCurrentMenu: boolean
    workingPeriods: MenuWorkingPeriod[]
    menuType: MenuType | null
}

export interface MenuWorkingPeriod {
    dayOfWeek: MenuWorkingPeriodDayOfWeek
    startTime: string
    endTime: string
}

export interface ExportProductsMenu {
    filename: string
    data: ArrayBuffer
}

export interface BaseMenuProduct {
    id: string
    price: number
}

export interface ProductsPayload {
    products: BaseMenuProduct[]
    menuId: string
}

export interface ProductOnMenu {
    menuId: string
    productPrice: number
}

export interface LiteEditProduct {
    id: string
    name: string
    description: string | null
    sku: string
    category: string
    baseValue: number | null
}

export interface EditError {
    id: string
    name: string
    error: string
}

export interface ResultMultipleProductsFailed {
    productName: string
    message: string
}

export interface ResultMultipleProducts {
    success: Product[]
    failed: ResultMultipleProductsFailed[]
}

export interface Image {
    id: string | null
    data: ArrayBuffer | null
    url: string | null
}

export interface BaseCategory {
    name: string
    order: number
    parentId: string | null
    image: Image | null
}

export interface Category {
    id: string
    name: string
    order: number
    parentId: string | null
    image: Image | null
}

export interface CategoryWithSubCategoriesSubCategoriesSubCategories {
    id: string
    name: string
    order: number
    parentId: string | null
    image: Image | null
}

export interface CategoryWithSubCategoriesSubCategories {
    id: string
    name: string
    order: number
    parentId: string | null
    image: Image | null
    subCategories: CategoryWithSubCategoriesSubCategoriesSubCategories[]
}

export interface CategoryWithSubCategories {
    id: string
    name: string
    order: number
    parentId: string | null
    image: Image | null
    subCategories: CategoryWithSubCategoriesSubCategories[]
}

export interface BaseCategoryV2 {
    name: string
    order: number | null
    parentId: string | null
    image: Image | null
}

export interface StoreBaseData {
    name: string
    fantasyName: string | null
    address: Address | null
    attendanceRadius: number
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    phone: string | null
    deliveryType: DeliveryType[] | null
    whatsapp: string | null
    bannerUrl: string | null
    deliveryFeePerKm: number
    fixedDeliveryFee: number
    hideDeliveryTime: boolean | null
}

export interface Store {
    name: string
    cnpj: string
    fantasyName: string | null
    address: Address
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    hideDeliveryTime: boolean | null
    slug: string
    phone: string | null
    whatsapp: string | null
    bannerUrl: string | null
    requiredCpf: boolean | null
    timezone: string | null
    id: string
    logoUrl: string | null
    attendanceRadius: number
    deliveryFeePerKm: number
    fixedDeliveryFee: number
    isManualMenu: boolean
    isOrganization: boolean
    additonalFields: StoreAdditionalFields[] | null
    deliveryType: DeliveryType[] | null
    partnerLogoUrl: string | null
    partnerPoweredByUrl: string | null
    partnerUrl: string | null
    lowestDeliveryValueByRadius: number | null
}

export interface EditStoreV2 {
    name: string
    fantasyName: string | null
    address: Address | null
    attendanceRadius: number
    primaryColor: string | null
    secondaryColor: string | null
    avgWaitTime: number
    phone: string | null
    deliveryType: DeliveryType[] | null
    whatsapp: string | null
    bannerUrl: string | null
    deliveryFeePerKm: number | null
    fixedDeliveryFee: number | null
    hideDeliveryTime: boolean | null
    deliveryFeeRadius: EditStoreDeliveryFeeRadius[] | null
    deliveryAvgWaitTime: number
    dineInAvgWaitTime: number
}

export interface StoreStatus {
    vendor: Vendor
    status: boolean
}

export interface ExternalStoreIntegration {
    externalStoreId: string
    name: string
    createdAt: Date
}

export interface GetStoreExternalIntegrationResponseDto {
    type: ExternalStoreType
    stores: ExternalStoreIntegration[]
}

export interface UpsertManyStoreExternalIntegrationsRequestDto {
    externalIds: string[]
    type: ExternalStoreType
}

export interface ExternalStoreIntegrationProductDto {
    externalId: string | null
    externalStoreId: string
    name: string
    description: string | null
    price: number
    sku: string | null
    isActive: boolean
    imageUrl: string | null
}

export interface ExternalStoreIntegrationSectionProductDto {
    externalId: string | null
    externalStoreId: string
    name: string
    description: string | null
    index: number
    price: number
    sku: string | null
    isActive: boolean
}

export interface ExternalStoreIntegrationSection {
    externalId: string
    name: string
    index: number
    isActive: boolean
    min: number
    max: number
    products: ExternalStoreIntegrationSectionProductDto[]
}

export interface ExternalStoreIntegrationProductWithSectionsDto {
    externalId: string | null
    externalStoreId: string
    name: string
    description: string | null
    price: number
    sku: string | null
    isActive: boolean
    imageUrl: string | null
    sections: ExternalStoreIntegrationSection[]
}

export interface ExternalStoreIntegrationCategoryDto {
    externalId: string
    externalStoreId: string
    name: string
    sku: string | null
    products: ExternalStoreIntegrationProductWithSectionsDto[]
}

export interface GetManyExternalStoreIntegrationCategoriesResponseDto {
    categories: ExternalStoreIntegrationCategoryDto[]
    totalOfProducts: number
}

export interface UpdateExternalProductSkuRequestDto {
    externalProductId: string | null
    currentSku: string | null
    newSku: string
}

export interface BaseRecipientManagingPartner {
    name: string
    email: string
    documentNumber: string
}

export interface RecipientManagingPartner {
    name: string
    email: string
    documentNumber: string
    createdAt: Date
    recipientId: string
    id: string
}

export interface UpdateRecipientManagingPartner {
    name: string
    email: string
    documentNumber: string
    id: string | null
}

export interface BaseRecipient {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
}

export interface Recipient {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
    managingPartners: RecipientManagingPartner[]
    createdAt: Date
    storeId: string
    id: string
}

export interface NewRecipient {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
    managingPartners: BaseRecipientManagingPartner[]
}

export interface NewRecipientV2 {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
    managingPartners: BaseRecipientManagingPartner[] | null
}

export interface UpdateRecipient {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
    managingPartners: UpdateRecipientManagingPartner[]
    storeId: string
    id: string
}

export interface UpdateRecipientV2 {
    name: string
    bankName: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    email: string | null
    documentNumber: string
    pagarmeRecipientAcquirerId: string | null
    managingPartners: UpdateRecipientManagingPartner[] | null
    storeId: string
    id: string
}

export interface AcquirerRecipient {
    id: string
    name: string
    bankCode: string
    branch: string
    branchDigit: string | null
    typeAccount: TypeAccount | null
    account: string
    accountDigit: string
    typeDocument: TypeDocument
    documentNumber: string
}

export interface ReceivableAmounts {
    totalAmount: number
    totalAvailable: number
    totalToReceive: number
    receivableDate: Date
}

export interface RecipientAcquirerResponse {
    type: RecipientAcquirerResponseType
    message: string[]
}

export interface VendorReport {
    vendor: Vendor
    value: number
    tip: number
    deliveryFee: number
}

export interface OrderReport {
    orderId: string
    customerName: string
    status: OrderStatus
    value: number
    deliveryFee: number
    deliveryType: DeliveryType
    tip: number
    date: Date
    channel: DeliveryChannel
    vendor: Vendor
    obs: string | null
    externalReference: string | null
    reference: number
    subTotal: number
    discountValue: number
    customerPhone: string | null
    paymentMethods: string[]
    discountValueDeliveryFee: number | null
    discountValueDeliveryFeeMarketplace: number | null
    discountValueMarketplace: number | null
}

export interface ProductOrderReport {
    productId: string
    sku: string
    deliveryOrderId: string
    name: string
    baseUnitPrice: number
    totalUnitPrice: number
    count: number
    obs: string | null
}

export interface GeneralProductOrderReport {
    productId: string
    sku: string
    name: string
    count: number
    totalValue: number
}

export interface GeneralProductOrderReportV2 {
    productId: string
    sku: string
    name: string
    count: number
    totalValue: number
    baseValue: number
    vendor: Vendor
}

export interface SalesAmount {
    currentSalesAmount: number
    currentAverageTicket: number
    previousSalesAmount: number
    previousAverageTicket: number
    growthRateSalesAmount: number
    growthRateAverageTicket: number
}

export interface BestSellingProductsReport {
    id: string
    productName: string
    amount: number
}

export interface ProductAverageTicket {
    productName: string
    averageTicket: number
}

export interface BestSellingProductFromVendor {
    ifood: BestSellingProductsReport[] | null
    rappi: BestSellingProductsReport[] | null
    ubereats: BestSellingProductsReport[] | null
}

export interface SalesFromVendor {
    vendor: string
    amount: number
    amountLiquid: number
    fees: number
    percentage: number
}

export interface RevenuePerDayReport {
    weekday: number
    currentAmount: number
    previousAmount: number
}

export interface RevenuePerHourReport {
    hour: number
    currentAmount: number
    previousAmount: number
}

export interface PaginationRequestDto {
    currentPage: number
    perPage: number
}

export interface PaginationResponseDto {
    currentPage: number
    perPage: number
    total: number
    lastPage: number
}

export interface GetCombinedOrdersReportRequestDtoPeriod {
    since: Date
    until: Date
}

export interface GetCombinedOrdersReportRequestDto {
    reference: string | null
    vendors: Vendor[] | null
    statuses: OrderStatus[] | null
    period: GetCombinedOrdersReportRequestDtoPeriod | null
}

export interface CombinedOrderReportPaymentDto {
    method: PaymentMethod
    brand: string | null
    change: number | null
    partnerPaymentId: string | null
    deliveryFee: number
    discountValue: number
    discountValueMarketplace: number | null
    discountValueDeliveryFee: number | null
    discountValueDeliveryFeeMarketplace: number | null
    tip: number
    subTotal: number
    total: number
}

export interface CombinedOrderReportSubItemDto {
    id: string
    sku: string
    name: string
    sectionId: string | null
    sectionName: string | null
    pricing: CombinedOrderReportSubItemDtoPricing
    price: number
    count: number
}

export interface CombinedOrderReportItemDto {
    id: string
    sku: string
    name: string
    count: number
    baseUnitPrice: number
    totalUnitPrice: number
    obs: string | null
    subItems: CombinedOrderReportSubItemDto[]
}

export interface GetCombinedOrderReportDto {
    id: string
    reference: number
    obs: string | null
    date: Date
    finishedAt: Date | null
    value: number
    channel: DeliveryChannel
    status: OrderStatus
    vendor: Vendor
    deliveryType: DeliveryType
    customerName: string
    customerAddress: string
    payment: CombinedOrderReportPaymentDto | null
    items: CombinedOrderReportItemDto[]
}

export interface GetCombinedOrdersReportResponseDto {
    items: GetCombinedOrderReportDto[]
    totalValue: number
    pagination: PaginationResponseDto
}

export interface IFoodIntegration {
    storeId: string
    ifoodId: string
    isActive: boolean
    ifoodName: string | null
    deliveryByIFood: boolean
    automaticallyAcceptedValue: number | null
}

export interface StorePlan {
    name: string
    description: string
    typePayInterval: TimeInterval
    termsOfUse: string | null
    paymentLink: string | null
    partnerId: string | null
    id: string
    agreementDetails: AgreementDetails
    features: Feature[]
}

export interface Plan {
    name: string
    description: string
    typePayInterval: TimeInterval
    termsOfUse: string | null
    paymentLink: string | null
    partnerId: string | null
    id: string
    statusAtStore: string
}

export interface PlanComplete {
    name: string
    description: string
    typePayInterval: TimeInterval
    termsOfUse: string | null
    paymentLink: string | null
    partnerId: string | null
    id: string
    statusAtStore: string
    features: Feature[]
    iconUrl: string | null
    value: number
    startAgreementAt: Date | null
    endAgreementAt: Date | null
}

export interface TrialPlanPeriod {
    payInterval: TrialPlanPeriodPayInterval
    count: number
}

export interface TrialPlan {
    planId: string
    period: TrialPlanPeriod
}

export class InvalidArgument extends SdkgenError {}

export class TokenExpired extends SdkgenError {}

export class TokenInvalid extends SdkgenError {}

export class DoesntExist extends SdkgenError {}

export class Conflict extends SdkgenError {}

export class NotFound extends SdkgenError {}

export class Fatal extends SdkgenError {}

export class ApiClient extends SdkgenHttpClient {
    constructor(baseUrl: string) {
        super(baseUrl, astJson, errClasses);
    }

    getBaseAddressFromPostalCode(args: {code: string}): Promise<BaseAddress> { return this.makeRequest("getBaseAddressFromPostalCode", args || {}); }
    getBaseAddressFromPostalCodeV2(args: {code: string}): Promise<BaseAddress> { return this.makeRequest("getBaseAddressFromPostalCodeV2", args || {}); }
    uploadImage(args: {auth: BaseStoreAuth, image: ArrayBuffer}): Promise<string> { return this.makeRequest("uploadImage", args || {}); }
    uploadBanner(args: {auth: BaseStoreAuth, banner: ArrayBuffer}): Promise<string> { return this.makeRequest("uploadBanner", args || {}); }
    getMyStore(args: {auth?: BaseStoreAuth | null, slug?: string | null}): Promise<Store> { return this.makeRequest("getMyStore", args || {}); }
    getMyStoreV2(args: {auth?: BaseStoreAuth | null}): Promise<StoreV2> { return this.makeRequest("getMyStoreV2", args || {}); }
    getStoreBySlug(args: {auth?: BaseStoreAuth | null, slug: string}): Promise<StoreBySlug> { return this.makeRequest("getStoreBySlug", args || {}); }
    getStorePaymentMethods(args: {auth?: BaseStoreAuth | null}): Promise<CompletePaymentMethod[]> { return this.makeRequest("getStorePaymentMethods", args || {}); }
    getPartnerDetails(args: {storeId?: string | null, storeSlug?: string | null}): Promise<PartnerDetails | null> { return this.makeRequest("getPartnerDetails", args || {}); }
    getMenus(args: {auth: BaseStoreAuth}): Promise<Menu[]> { return this.makeRequest("getMenus", args || {}); }
    getMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<Menu> { return this.makeRequest("getMenu", args || {}); }
    addMenu(args: {auth: BaseStoreAuth, name: string, menuType?: MenuType | null}): Promise<Menu> { return this.makeRequest("addMenu", args || {}); }
    deleteMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<void> { return this.makeRequest("deleteMenu", args || {}); }
    updateMenu(args: {auth: BaseStoreAuth, menuId: string, name: string, menuType?: MenuType | null}): Promise<void> { return this.makeRequest("updateMenu", args || {}); }
    setDefaultMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<void> { return this.makeRequest("setDefaultMenu", args || {}); }
    addProductToMenu(args: {auth: BaseStoreAuth, productId: string, menuId: string, price: number}): Promise<void> { return this.makeRequest("addProductToMenu", args || {}); }
    addProductsToMenu(args: {auth: BaseStoreAuth, data: ProductsPayload, failFast: boolean}): Promise<void> { return this.makeRequest("addProductsToMenu", args || {}); }
    editMenuProductPrice(args: {auth: BaseStoreAuth, productId: string, menuId: string, price: number}): Promise<void> { return this.makeRequest("editMenuProductPrice", args || {}); }
    removeProductFromMenu(args: {auth: BaseStoreAuth, productId: string, menuId: string}): Promise<void> { return this.makeRequest("removeProductFromMenu", args || {}); }
    removeProductsFromMenu(args: {auth: BaseStoreAuth, productIds: string[], menuId: string}): Promise<void> { return this.makeRequest("removeProductsFromMenu", args || {}); }
    getProductsFromMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<MenuProduct[]> { return this.makeRequest("getProductsFromMenu", args || {}); }
    getCompleteProductsFromMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<CompleteMenuProduct[]> { return this.makeRequest("getCompleteProductsFromMenu", args || {}); }
    exportProductsFromMenu(args: {auth: BaseStoreAuth, menuId: string}): Promise<ExportProductsMenu> { return this.makeRequest("exportProductsFromMenu", args || {}); }
    importProductsFromMenu(args: {auth: BaseStoreAuth, menuId: string, data: ArrayBuffer, storeId: string}): Promise<void> { return this.makeRequest("importProductsFromMenu", args || {}); }
    getMenuWorkingPeriods(args: {auth: BaseStoreAuth, menuId: string}): Promise<MenuWorkingPeriod[]> { return this.makeRequest("getMenuWorkingPeriods", args || {}); }
    setMenuWorkingPeriods(args: {auth: BaseStoreAuth, menuId: string, workingPeriods: MenuWorkingPeriod[]}): Promise<void> { return this.makeRequest("setMenuWorkingPeriods", args || {}); }
    addProductToMenus(args: {auth: BaseStoreAuth, product: BaseMenuProduct, menuIds: string[], failFast: boolean}): Promise<void> { return this.makeRequest("addProductToMenus", args || {}); }
    removeProductFromMenus(args: {auth: BaseStoreAuth, productId: string, menuIds: string[]}): Promise<void> { return this.makeRequest("removeProductFromMenus", args || {}); }
    activateManualMode(args: {auth: BaseStoreAuth}): Promise<void> { return this.makeRequest("activateManualMode", args || {}); }
    deactivateManualMode(args: {auth: BaseStoreAuth}): Promise<void> { return this.makeRequest("deactivateManualMode", args || {}); }
    setManualMenu(args: {auth: BaseStoreAuth, menuId?: string | null}): Promise<void> { return this.makeRequest("setManualMenu", args || {}); }
    addProduct(args: {auth: BaseStoreAuth, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("addProduct", args || {}); }
    addProductV2(args: {auth: BaseStoreAuth, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("addProductV2", args || {}); }
    addProductV3(args: {auth: BaseStoreAuth, product: BaseProductWithSections, imageUrl?: string | null, menus: ProductOnMenu[]}): Promise<Product> { return this.makeRequest("addProductV3", args || {}); }
    getProducts(args: {auth: BaseStoreAuth}): Promise<Product[]> { return this.makeRequest("getProducts", args || {}); }
    getProduct(args: {auth: BaseStoreAuth, productId: string}): Promise<Product> { return this.makeRequest("getProduct", args || {}); }
    getProductByExternalId(args: {auth: BaseStoreAuth, sku: string}): Promise<Product> { return this.makeRequest("getProductByExternalId", args || {}); }
    removeProduct(args: {auth: BaseStoreAuth, productId: string}): Promise<void> { return this.makeRequest("removeProduct", args || {}); }
    removeMultipleProducts(args: {auth: BaseStoreAuth, productIds: string[]}): Promise<void> { return this.makeRequest("removeMultipleProducts", args || {}); }
    removeProductByExternalId(args: {auth: BaseStoreAuth, sku: string}): Promise<void> { return this.makeRequest("removeProductByExternalId", args || {}); }
    editProduct(args: {auth: BaseStoreAuth, productId: string, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("editProduct", args || {}); }
    editProductV2(args: {auth: BaseStoreAuth, productId: string, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("editProductV2", args || {}); }
    editProductByExternalId(args: {auth: BaseStoreAuth, sku: string, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("editProductByExternalId", args || {}); }
    editProductByExternalIdV2(args: {auth: BaseStoreAuth, sku: string, product: BaseProductWithSections, imageUrl?: string | null}): Promise<Product> { return this.makeRequest("editProductByExternalIdV2", args || {}); }
    liteEditProducts(args: {auth: BaseStoreAuth, products: LiteEditProduct[]}): Promise<EditError[]> { return this.makeRequest("liteEditProducts", args || {}); }
    activateProduct(args: {auth: BaseStoreAuth, productId: string}): Promise<void> { return this.makeRequest("activateProduct", args || {}); }
    deactivateProduct(args: {auth: BaseStoreAuth, productId: string}): Promise<void> { return this.makeRequest("deactivateProduct", args || {}); }
    activateProductByExternalId(args: {auth: BaseStoreAuth, sku: string}): Promise<void> { return this.makeRequest("activateProductByExternalId", args || {}); }
    deactivateProductByExternalId(args: {auth: BaseStoreAuth, sku: string}): Promise<void> { return this.makeRequest("deactivateProductByExternalId", args || {}); }
    getCompleteProduct(args: {auth: BaseStoreAuth, productId: string}): Promise<CompleteProduct> { return this.makeRequest("getCompleteProduct", args || {}); }
    getCompleteProductV2(args: {auth: BaseStoreAuth, productId: string}): Promise<CompleteProduct> { return this.makeRequest("getCompleteProductV2", args || {}); }
    getCompleteProductV3(args: {auth: BaseStoreAuth, productId: string}): Promise<CompleteProductWithMenus> { return this.makeRequest("getCompleteProductV3", args || {}); }
    getCompleteProductByExternalId(args: {auth: BaseStoreAuth, sku: string}): Promise<CompleteProduct> { return this.makeRequest("getCompleteProductByExternalId", args || {}); }
    addMultipleProducts(args: {auth: BaseStoreAuth, products: MultipleProducts[]}): Promise<ResultMultipleProducts> { return this.makeRequest("addMultipleProducts", args || {}); }
    upsertMultipleProducts(args: {auth: BaseStoreAuth, products: BaseProductWithSections[]}): Promise<Product[]> { return this.makeRequest("upsertMultipleProducts", args || {}); }
    getProductsV2(args: {auth: BaseStoreAuth}): Promise<Product[]> { return this.makeRequest("getProductsV2", args || {}); }
    getSupply(args: {auth: BaseStoreAuth}): Promise<Product[]> { return this.makeRequest("getSupply", args || {}); }
    getOrder(args: {auth: BaseStoreAuth, orderId: string}): Promise<DeliveryOrder> { return this.makeRequest("getOrder", args || {}); }
    payOrderWithPix(args: {auth: BaseStoreAuth, pixChargeId: string, orderId: string}): Promise<void> { return this.makeRequest("payOrderWithPix", args || {}); }
    getCategories(args: {auth: BaseStoreAuth}): Promise<Category[]> { return this.makeRequest("getCategories", args || {}); }
    addCategory(args: {auth: BaseStoreAuth, category: BaseCategory}): Promise<Category> { return this.makeRequest("addCategory", args || {}); }
    deleteCategory(args: {auth: BaseStoreAuth, categoryId: string}): Promise<void> { return this.makeRequest("deleteCategory", args || {}); }
    updateCategory(args: {auth: BaseStoreAuth, categoryId: string, category: BaseCategory}): Promise<Category> { return this.makeRequest("updateCategory", args || {}); }
    getCategoriesWithSubcategories(args: {auth: BaseStoreAuth}): Promise<CategoryWithSubCategories[]> { return this.makeRequest("getCategoriesWithSubcategories", args || {}); }
    getCategoriesWithoutSubcategories(args: {auth: BaseStoreAuth}): Promise<Category[]> { return this.makeRequest("getCategoriesWithoutSubcategories", args || {}); }
    addMultipleCategories(args: {auth: BaseStoreAuth, categories: BaseCategoryV2[]}): Promise<Category[]> { return this.makeRequest("addMultipleCategories", args || {}); }
    editStore(args: {auth: BaseStoreAuth, store: StoreBaseData, imageUrl?: string | null}): Promise<Store> { return this.makeRequest("editStore", args || {}); }
    editStoreV2(args: {auth: BaseStoreAuth, store: EditStoreV2, imageUrl?: string | null}): Promise<StoreV2> { return this.makeRequest("editStoreV2", args || {}); }
    setStorePaymentMethods(args: {auth: BaseStoreAuth, methods: CompletePaymentMethod[]}): Promise<void> { return this.makeRequest("setStorePaymentMethods", args || {}); }
    getAllPossibleMethods(args?: {}): Promise<CompletePaymentMethod[]> { return this.makeRequest("getAllPossibleMethods", args || {}); }
    setStorePaymentMethodsV2(args: {auth: BaseStoreAuth, methods: CompletePaymentMethod[]}): Promise<void> { return this.makeRequest("setStorePaymentMethodsV2", args || {}); }
    getAllPossibleMethodsV2(args?: {}): Promise<CompletePaymentMethod[]> { return this.makeRequest("getAllPossibleMethodsV2", args || {}); }
    isAptToChargeDeliveryFee(args: {auth: BaseStoreAuth}): Promise<boolean> { return this.makeRequest("isAptToChargeDeliveryFee", args || {}); }
    getStoreStatuses(args: {auth: BaseStoreAuth}): Promise<StoreStatus[]> { return this.makeRequest("getStoreStatuses", args || {}); }
    updateStoreToken(args: {auth: BaseStoreAuth, newApiKey: string, newStoreId: string}): Promise<void> { return this.makeRequest("updateStoreToken", args || {}); }
    getStoreExternalIntegrations(args: {auth: BaseStoreAuth}): Promise<GetStoreExternalIntegrationResponseDto[]> { return this.makeRequest("getStoreExternalIntegrations", args || {}); }
    upsertManyExternalStoreIntegrations(args: {auth: BaseStoreAuth, request: UpsertManyStoreExternalIntegrationsRequestDto}): Promise<GetStoreExternalIntegrationResponseDto[]> { return this.makeRequest("upsertManyExternalStoreIntegrations", args || {}); }
    getManyExternalStoreIntegrationCategoriesWithProductsWithSections(args: {auth: BaseStoreAuth, type: ExternalStoreType}): Promise<GetManyExternalStoreIntegrationCategoriesResponseDto> { return this.makeRequest("getManyExternalStoreIntegrationCategoriesWithProductsWithSections", args || {}); }
    updateExternalProductSku(args: {auth: BaseStoreAuth, externalStoreId: string, request: UpdateExternalProductSkuRequestDto}): Promise<void> { return this.makeRequest("updateExternalProductSku", args || {}); }
    undoExternalProductSkuUpdate(args: {auth: BaseStoreAuth, externalStoreId: string, externalProductId?: string | null, sku?: string | null}): Promise<void> { return this.makeRequest("undoExternalProductSkuUpdate", args || {}); }
    updateManyExternalProductSku(args: {auth: BaseStoreAuth, externalStoreId: string, request: UpdateExternalProductSkuRequestDto[]}): Promise<void> { return this.makeRequest("updateManyExternalProductSku", args || {}); }
    processUpdateManyExternalProductsSkus(args: {auth: BaseStoreAuth, externalStoresIds: string[]}): Promise<void> { return this.makeRequest("processUpdateManyExternalProductsSkus", args || {}); }
    getAdditionalFields(args: {auth: BaseStoreAuth}): Promise<StoreAdditionalFields[]> { return this.makeRequest("getAdditionalFields", args || {}); }
    setAdditionalFields(args: {auth: BaseStoreAuth, additionalFields: StoreAdditionalFields[]}): Promise<StoreAdditionalFields[]> { return this.makeRequest("setAdditionalFields", args || {}); }
    addRecipient(args: {auth: BaseStoreAuth, recipient: NewRecipient}): Promise<Recipient> { return this.makeRequest("addRecipient", args || {}); }
    addRecipientV2(args: {auth: BaseStoreAuth, recipient: NewRecipientV2}): Promise<Recipient> { return this.makeRequest("addRecipientV2", args || {}); }
    getRecipient(args: {auth: BaseStoreAuth}): Promise<Recipient | null> { return this.makeRequest("getRecipient", args || {}); }
    editRecipient(args: {auth: BaseStoreAuth, recipient: UpdateRecipient}): Promise<Recipient> { return this.makeRequest("editRecipient", args || {}); }
    editRecipientV2(args: {auth: BaseStoreAuth, recipient: UpdateRecipientV2}): Promise<RecipientAcquirerResponse> { return this.makeRequest("editRecipientV2", args || {}); }
    removeRecipient(args: {auth: BaseStoreAuth, recipientId: string}): Promise<void> { return this.makeRequest("removeRecipient", args || {}); }
    registerRecipientOnAcquirer(args: {auth: BaseStoreAuth}): Promise<RecipientAcquirerResponse> { return this.makeRequest("registerRecipientOnAcquirer", args || {}); }
    updateRecipientOnAcquirer(args: {auth: BaseStoreAuth}): Promise<RecipientAcquirerResponse> { return this.makeRequest("updateRecipientOnAcquirer", args || {}); }
    getReceivableAmounts(args: {auth: BaseStoreAuth}): Promise<ReceivableAmounts> { return this.makeRequest("getReceivableAmounts", args || {}); }
    getOrdersByVendorReport(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<VendorReport[]> { return this.makeRequest("getOrdersByVendorReport", args || {}); }
    getAllOrdersReport(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<OrderReport[]> { return this.makeRequest("getAllOrdersReport", args || {}); }
    getAllOrdersProductsReport(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<ProductOrderReport[]> { return this.makeRequest("getAllOrdersProductsReport", args || {}); }
    getGeneralProductReport(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<GeneralProductOrderReport[]> { return this.makeRequest("getGeneralProductReport", args || {}); }
    getGeneralProductReportV2(args: {auth: BaseStoreAuth, since: Date, until: Date, vendors?: Vendor[] | null}): Promise<GeneralProductOrderReportV2[]> { return this.makeRequest("getGeneralProductReportV2", args || {}); }
    getSalesAmountAndAverageTicket(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<SalesAmount> { return this.makeRequest("getSalesAmountAndAverageTicket", args || {}); }
    getProductAverageTicket(args: {auth: BaseStoreAuth, productId: string, since: Date, until: Date}): Promise<ProductAverageTicket> { return this.makeRequest("getProductAverageTicket", args || {}); }
    getBestSellingProducts(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<BestSellingProductsReport[] | null> { return this.makeRequest("getBestSellingProducts", args || {}); }
    getBestSellingProductsFromVendor(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<BestSellingProductFromVendor | null> { return this.makeRequest("getBestSellingProductsFromVendor", args || {}); }
    getSalesFromVendor(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<SalesFromVendor[]> { return this.makeRequest("getSalesFromVendor", args || {}); }
    getRevenuePerDay(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<RevenuePerDayReport[]> { return this.makeRequest("getRevenuePerDay", args || {}); }
    getRevenuePerHour(args: {auth: BaseStoreAuth, since: Date, until: Date}): Promise<RevenuePerHourReport[]> { return this.makeRequest("getRevenuePerHour", args || {}); }
    getCombinedOrdersReport(args: {auth: BaseStoreAuth, filters?: GetCombinedOrdersReportRequestDto | null, orderBy?: GetCombinedOrdersReportOrderBy | null, orderDirection?: GetCombinedOrdersReportOrderDirection | null, pagination: PaginationRequestDto}): Promise<GetCombinedOrdersReportResponseDto> { return this.makeRequest("getCombinedOrdersReport", args || {}); }
    exportCombinedOrdersReport(args: {auth: BaseStoreAuth, createdBy?: string | null, filters?: GetCombinedOrdersReportRequestDto | null, orderBy?: GetCombinedOrdersReportOrderBy | null, orderDirection?: GetCombinedOrdersReportOrderDirection | null}): Promise<ArrayBuffer> { return this.makeRequest("exportCombinedOrdersReport", args || {}); }
    getIFoodIntegration(args: {auth: BaseStoreAuth}): Promise<IFoodIntegration[] | null> { return this.makeRequest("getIFoodIntegration", args || {}); }
    editIntegrationIFood(args: {auth: BaseStoreAuth, integration: IFoodIntegration}): Promise<boolean | null> { return this.makeRequest("editIntegrationIFood", args || {}); }
    syncCompleteIfoodMenu(args: {auth: BaseStoreAuth}): Promise<Product[]> { return this.makeRequest("syncCompleteIfoodMenu", args || {}); }
    getActiveStorePlans(args: {auth: BaseStoreAuth}): Promise<StorePlan[]> { return this.makeRequest("getActiveStorePlans", args || {}); }
    getStorePlans(args: {auth: BaseStoreAuth}): Promise<StorePlan[]> { return this.makeRequest("getStorePlans", args || {}); }
    getPlansAtPartner(args: {auth: BaseStoreAuth}): Promise<PlanComplete[]> { return this.makeRequest("getPlansAtPartner", args || {}); }
    setTrialPlanAtStore(args: {auth: BaseStoreAuth, setupTrialPlan: TrialPlan}): Promise<void> { return this.makeRequest("setTrialPlanAtStore", args || {}); }
}

const errClasses = {
    InvalidArgument,
    TokenExpired,
    TokenInvalid,
    DoesntExist,
    Conflict,
    NotFound,
    Fatal
};

const astJson = {
    annotations: {},
    errors: [
        "InvalidArgument",
        "TokenExpired",
        "TokenInvalid",
        "DoesntExist",
        "Conflict",
        "NotFound",
        "Fatal"
    ],
    functionTable: {
        getBaseAddressFromPostalCode: {
            args: {
                code: "string"
            },
            ret: "BaseAddress"
        },
        getBaseAddressFromPostalCodeV2: {
            args: {
                code: "string"
            },
            ret: "BaseAddress"
        },
        uploadImage: {
            args: {
                auth: "BaseStoreAuth",
                image: "bytes"
            },
            ret: "string"
        },
        uploadBanner: {
            args: {
                auth: "BaseStoreAuth",
                banner: "bytes"
            },
            ret: "string"
        },
        getMyStore: {
            args: {
                auth: "BaseStoreAuth?",
                slug: "string?"
            },
            ret: "Store"
        },
        getMyStoreV2: {
            args: {
                auth: "BaseStoreAuth?"
            },
            ret: "StoreV2"
        },
        getStoreBySlug: {
            args: {
                auth: "BaseStoreAuth?",
                slug: "string"
            },
            ret: "StoreBySlug"
        },
        getStorePaymentMethods: {
            args: {
                auth: "BaseStoreAuth?"
            },
            ret: "CompletePaymentMethod[]"
        },
        getPartnerDetails: {
            args: {
                storeId: "string?",
                storeSlug: "string?"
            },
            ret: "PartnerDetails?"
        },
        getMenus: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Menu[]"
        },
        getMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "Menu"
        },
        addMenu: {
            args: {
                auth: "BaseStoreAuth",
                name: "string",
                menuType: "MenuType?"
            },
            ret: "Menu"
        },
        deleteMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "void"
        },
        updateMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string",
                name: "string",
                menuType: "MenuType?"
            },
            ret: "void"
        },
        setDefaultMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "void"
        },
        addProductToMenu: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                menuId: "string",
                price: "uint"
            },
            ret: "void"
        },
        addProductsToMenu: {
            args: {
                auth: "BaseStoreAuth",
                data: "ProductsPayload",
                failFast: "bool"
            },
            ret: "void"
        },
        editMenuProductPrice: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                menuId: "string",
                price: "uint"
            },
            ret: "void"
        },
        removeProductFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                menuId: "string"
            },
            ret: "void"
        },
        removeProductsFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                productIds: "string[]",
                menuId: "string"
            },
            ret: "void"
        },
        getProductsFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "MenuProduct[]"
        },
        getCompleteProductsFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "CompleteMenuProduct[]"
        },
        exportProductsFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "ExportProductsMenu"
        },
        importProductsFromMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string",
                data: "bytes",
                storeId: "uuid"
            },
            ret: "void"
        },
        getMenuWorkingPeriods: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string"
            },
            ret: "MenuWorkingPeriod[]"
        },
        setMenuWorkingPeriods: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string",
                workingPeriods: "MenuWorkingPeriod[]"
            },
            ret: "void"
        },
        addProductToMenus: {
            args: {
                auth: "BaseStoreAuth",
                product: "BaseMenuProduct",
                menuIds: "uuid[]",
                failFast: "bool"
            },
            ret: "void"
        },
        removeProductFromMenus: {
            args: {
                auth: "BaseStoreAuth",
                productId: "uuid",
                menuIds: "uuid[]"
            },
            ret: "void"
        },
        activateManualMode: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "void"
        },
        deactivateManualMode: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "void"
        },
        setManualMenu: {
            args: {
                auth: "BaseStoreAuth",
                menuId: "string?"
            },
            ret: "void"
        },
        addProduct: {
            args: {
                auth: "BaseStoreAuth",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        addProductV2: {
            args: {
                auth: "BaseStoreAuth",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        addProductV3: {
            args: {
                auth: "BaseStoreAuth",
                product: "BaseProductWithSections",
                imageUrl: "string?",
                menus: "ProductOnMenu[]"
            },
            ret: "Product"
        },
        getProducts: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Product[]"
        },
        getProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "Product"
        },
        getProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string"
            },
            ret: "Product"
        },
        removeProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "void"
        },
        removeMultipleProducts: {
            args: {
                auth: "BaseStoreAuth",
                productIds: "string[]"
            },
            ret: "void"
        },
        removeProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string"
            },
            ret: "void"
        },
        editProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        editProductV2: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        editProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        editProductByExternalIdV2: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string",
                product: "BaseProductWithSections",
                imageUrl: "string?"
            },
            ret: "Product"
        },
        liteEditProducts: {
            args: {
                auth: "BaseStoreAuth",
                products: "LiteEditProduct[]"
            },
            ret: "EditError[]"
        },
        activateProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "void"
        },
        deactivateProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "void"
        },
        activateProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string"
            },
            ret: "void"
        },
        deactivateProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string"
            },
            ret: "void"
        },
        getCompleteProduct: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "CompleteProduct"
        },
        getCompleteProductV2: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "CompleteProduct"
        },
        getCompleteProductV3: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string"
            },
            ret: "CompleteProductWithMenus"
        },
        getCompleteProductByExternalId: {
            args: {
                auth: "BaseStoreAuth",
                sku: "string"
            },
            ret: "CompleteProduct"
        },
        addMultipleProducts: {
            args: {
                auth: "BaseStoreAuth",
                products: "MultipleProducts[]"
            },
            ret: "ResultMultipleProducts"
        },
        upsertMultipleProducts: {
            args: {
                auth: "BaseStoreAuth",
                products: "BaseProductWithSections[]"
            },
            ret: "Product[]"
        },
        getProductsV2: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Product[]"
        },
        getSupply: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Product[]"
        },
        getOrder: {
            args: {
                auth: "BaseStoreAuth",
                orderId: "string"
            },
            ret: "DeliveryOrder"
        },
        payOrderWithPix: {
            args: {
                auth: "BaseStoreAuth",
                pixChargeId: "string",
                orderId: "string"
            },
            ret: "void"
        },
        getCategories: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Category[]"
        },
        addCategory: {
            args: {
                auth: "BaseStoreAuth",
                category: "BaseCategory"
            },
            ret: "Category"
        },
        deleteCategory: {
            args: {
                auth: "BaseStoreAuth",
                categoryId: "string"
            },
            ret: "void"
        },
        updateCategory: {
            args: {
                auth: "BaseStoreAuth",
                categoryId: "string",
                category: "BaseCategory"
            },
            ret: "Category"
        },
        getCategoriesWithSubcategories: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "CategoryWithSubCategories[]"
        },
        getCategoriesWithoutSubcategories: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Category[]"
        },
        addMultipleCategories: {
            args: {
                auth: "BaseStoreAuth",
                categories: "BaseCategoryV2[]"
            },
            ret: "Category[]"
        },
        editStore: {
            args: {
                auth: "BaseStoreAuth",
                store: "StoreBaseData",
                imageUrl: "string?"
            },
            ret: "Store"
        },
        editStoreV2: {
            args: {
                auth: "BaseStoreAuth",
                store: "EditStoreV2",
                imageUrl: "string?"
            },
            ret: "StoreV2"
        },
        setStorePaymentMethods: {
            args: {
                auth: "BaseStoreAuth",
                methods: "CompletePaymentMethod[]"
            },
            ret: "void"
        },
        getAllPossibleMethods: {
            args: {},
            ret: "CompletePaymentMethod[]"
        },
        setStorePaymentMethodsV2: {
            args: {
                auth: "BaseStoreAuth",
                methods: "CompletePaymentMethod[]"
            },
            ret: "void"
        },
        getAllPossibleMethodsV2: {
            args: {},
            ret: "CompletePaymentMethod[]"
        },
        isAptToChargeDeliveryFee: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "bool"
        },
        getStoreStatuses: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "StoreStatus[]"
        },
        updateStoreToken: {
            args: {
                auth: "BaseStoreAuth",
                newApiKey: "string",
                newStoreId: "string"
            },
            ret: "void"
        },
        getStoreExternalIntegrations: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "GetStoreExternalIntegrationResponseDto[]"
        },
        upsertManyExternalStoreIntegrations: {
            args: {
                auth: "BaseStoreAuth",
                request: "UpsertManyStoreExternalIntegrationsRequestDto"
            },
            ret: "GetStoreExternalIntegrationResponseDto[]"
        },
        getManyExternalStoreIntegrationCategoriesWithProductsWithSections: {
            args: {
                auth: "BaseStoreAuth",
                type: "ExternalStoreType"
            },
            ret: "GetManyExternalStoreIntegrationCategoriesResponseDto"
        },
        updateExternalProductSku: {
            args: {
                auth: "BaseStoreAuth",
                externalStoreId: "string",
                request: "UpdateExternalProductSkuRequestDto"
            },
            ret: "void"
        },
        undoExternalProductSkuUpdate: {
            args: {
                auth: "BaseStoreAuth",
                externalStoreId: "string",
                externalProductId: "string?",
                sku: "string?"
            },
            ret: "void"
        },
        updateManyExternalProductSku: {
            args: {
                auth: "BaseStoreAuth",
                externalStoreId: "string",
                request: "UpdateExternalProductSkuRequestDto[]"
            },
            ret: "void"
        },
        processUpdateManyExternalProductsSkus: {
            args: {
                auth: "BaseStoreAuth",
                externalStoresIds: "string[]"
            },
            ret: "void"
        },
        getAdditionalFields: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "StoreAdditionalFields[]"
        },
        setAdditionalFields: {
            args: {
                auth: "BaseStoreAuth",
                additionalFields: "StoreAdditionalFields[]"
            },
            ret: "StoreAdditionalFields[]"
        },
        addRecipient: {
            args: {
                auth: "BaseStoreAuth",
                recipient: "NewRecipient"
            },
            ret: "Recipient"
        },
        addRecipientV2: {
            args: {
                auth: "BaseStoreAuth",
                recipient: "NewRecipientV2"
            },
            ret: "Recipient"
        },
        getRecipient: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Recipient?"
        },
        editRecipient: {
            args: {
                auth: "BaseStoreAuth",
                recipient: "UpdateRecipient"
            },
            ret: "Recipient"
        },
        editRecipientV2: {
            args: {
                auth: "BaseStoreAuth",
                recipient: "UpdateRecipientV2"
            },
            ret: "RecipientAcquirerResponse"
        },
        removeRecipient: {
            args: {
                auth: "BaseStoreAuth",
                recipientId: "string"
            },
            ret: "void"
        },
        registerRecipientOnAcquirer: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "RecipientAcquirerResponse"
        },
        updateRecipientOnAcquirer: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "RecipientAcquirerResponse"
        },
        getReceivableAmounts: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "ReceivableAmounts"
        },
        getOrdersByVendorReport: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "VendorReport[]"
        },
        getAllOrdersReport: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "OrderReport[]"
        },
        getAllOrdersProductsReport: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "ProductOrderReport[]"
        },
        getGeneralProductReport: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "GeneralProductOrderReport[]"
        },
        getGeneralProductReportV2: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime",
                vendors: "Vendor[]?"
            },
            ret: "GeneralProductOrderReportV2[]"
        },
        getSalesAmountAndAverageTicket: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "SalesAmount"
        },
        getProductAverageTicket: {
            args: {
                auth: "BaseStoreAuth",
                productId: "string",
                since: "datetime",
                until: "datetime"
            },
            ret: "ProductAverageTicket"
        },
        getBestSellingProducts: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "BestSellingProductsReport[]?"
        },
        getBestSellingProductsFromVendor: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "BestSellingProductFromVendor?"
        },
        getSalesFromVendor: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "SalesFromVendor[]"
        },
        getRevenuePerDay: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "RevenuePerDayReport[]"
        },
        getRevenuePerHour: {
            args: {
                auth: "BaseStoreAuth",
                since: "datetime",
                until: "datetime"
            },
            ret: "RevenuePerHourReport[]"
        },
        getCombinedOrdersReport: {
            args: {
                auth: "BaseStoreAuth",
                filters: "GetCombinedOrdersReportRequestDto?",
                orderBy: "GetCombinedOrdersReportOrderBy?",
                orderDirection: "GetCombinedOrdersReportOrderDirection?",
                pagination: "PaginationRequestDto"
            },
            ret: "GetCombinedOrdersReportResponseDto"
        },
        exportCombinedOrdersReport: {
            args: {
                auth: "BaseStoreAuth",
                createdBy: "string?",
                filters: "GetCombinedOrdersReportRequestDto?",
                orderBy: "GetCombinedOrdersReportOrderBy?",
                orderDirection: "GetCombinedOrdersReportOrderDirection?"
            },
            ret: "bytes"
        },
        getIFoodIntegration: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "IFoodIntegration[]?"
        },
        editIntegrationIFood: {
            args: {
                auth: "BaseStoreAuth",
                integration: "IFoodIntegration"
            },
            ret: "bool?"
        },
        syncCompleteIfoodMenu: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "Product[]"
        },
        getActiveStorePlans: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "StorePlan[]"
        },
        getStorePlans: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "StorePlan[]"
        },
        getPlansAtPartner: {
            args: {
                auth: "BaseStoreAuth"
            },
            ret: "PlanComplete[]"
        },
        setTrialPlanAtStore: {
            args: {
                auth: "BaseStoreAuth",
                setupTrialPlan: "TrialPlan"
            },
            ret: "void"
        }
    },
    typeTable: {
        StoreAdditionalFieldsOptions: {
            id: "string?",
            label: "string"
        },
        StoreAdditionalFields: {
            id: "string?",
            required: "bool",
            slug: "string",
            label: "string",
            type: "AdditionalFieldType",
            options: "StoreAdditionalFieldsOptions[]?"
        },
        StoreAdditionalFieldAnswerValues: {
            id: "string",
            label: "string",
            value: "string"
        },
        StoreAdditionalFieldAnswer: {
            id: "string",
            value: "string"
        },
        BaseAddress: {
            streetName: "string",
            city: "string",
            country: "string",
            state: "string",
            neighborhood: "string",
            postalCode: "string",
            complement: "string?"
        },
        Address: {
            streetName: "string",
            city: "string",
            country: "string",
            state: "string",
            neighborhood: "string",
            postalCode: "string",
            complement: "string?",
            formattedAddress: "string",
            streetNumber: "string"
        },
        BaseProduct: {
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?"
        },
        BaseSectionProduct: {
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            price: "uint",
            maxCount: "uint?",
            order: "uint",
            id: "string"
        },
        MountableSection: {
            id: "string",
            title: "string",
            min: "uint",
            max: "uint",
            pricing: "MountableSectionPricing",
            order: "uint",
            products: "BaseSectionProduct[]"
        },
        BaseProductWithSections: {
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            mountableSections: "MountableSection[]"
        },
        Product: {
            id: "string",
            isActive: "bool",
            isMountable: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?"
        },
        CompleteProduct: {
            id: "string",
            isActive: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            mountableSections: "MountableSection[]"
        },
        CompleteProductWithMenusMenus: {
            id: "uuid",
            name: "string"
        },
        CompleteProductWithMenus: {
            id: "string",
            isActive: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            mountableSections: "MountableSection[]",
            menus: "CompleteProductWithMenusMenus[]"
        },
        MenuProduct: {
            price: "uint",
            minProductValue: "uint?",
            id: "string",
            isActive: "bool",
            isMountable: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?"
        },
        MenuCategoryWithProductProducts: {
            id: "string",
            isActive: "bool",
            isMountable: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            minProductValue: "uint?",
            price: "uint"
        },
        MenuCategoryWithProduct: {
            id: "string",
            name: "string",
            parentId: "string?",
            parentName: "string?",
            order: "int",
            categoryImageUrl: "string?",
            parentImageUrl: "string?",
            products: "MenuCategoryWithProductProducts[]"
        },
        CompleteMenuProduct: {
            price: "uint",
            id: "string",
            isActive: "bool",
            imageUrl: "string?",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            mountableSections: "MountableSection[]"
        },
        MultipleProducts: {
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?",
            image: "bytes?"
        },
        BaseStoreAuth: {
            storeId: "string",
            authToken: "string"
        },
        BasePartnerAuth: {
            partnerId: "string",
            authToken: "string"
        },
        TemporaryToken: {
            expiresAt: "datetime",
            token: "string",
            storeId: "string"
        },
        DeliveryOrderCustomer: {
            name: "string?",
            email: "string?",
            phone: "string?",
            isWhatsapp: "bool?",
            documentNumber: "string?",
            documentType: "DeliveryOrderCustomerDocumentType?",
            address: "Address"
        },
        DeliveryOrderStore: {
            id: "string",
            name: "string",
            address: "Address"
        },
        DeliveryOrderMenu: {
            id: "string",
            name: "string"
        },
        DeliveryOrderPayment: {
            value: "uint",
            method: "PaymentMethod",
            brand: "string?",
            change: "uint?",
            partnerPaymentId: "string?"
        },
        DeliveryOrderItemsSubItems: {
            id: "string",
            sku: "string",
            name: "string",
            sectionId: "string?",
            sectionName: "string?",
            pricing: "DeliveryOrderItemsSubItemsPricing",
            price: "uint",
            count: "uint"
        },
        DeliveryOrderItems: {
            id: "string",
            sku: "string",
            name: "string",
            count: "uint",
            baseUnitPrice: "uint",
            totalUnitPrice: "uint",
            obs: "string?",
            subItems: "DeliveryOrderItemsSubItems[]"
        },
        DeliveryOrder: {
            id: "string",
            reference: "uint",
            obs: "string?",
            date: "datetime",
            customer: "DeliveryOrderCustomer",
            value: "uint",
            discountValue: "uint",
            discountValueMarketplace: "uint?",
            discountValueDeliveryFee: "uint?",
            discountValueDeliveryFeeMarketplace: "uint?",
            subTotal: "uint",
            deliveryFee: "uint",
            tip: "uint",
            channel: "DeliveryChannel",
            status: "OrderStatus",
            vendor: "Vendor",
            deliveryType: "DeliveryType",
            store: "DeliveryOrderStore",
            menu: "DeliveryOrderMenu?",
            payment: "DeliveryOrderPayment?",
            items: "DeliveryOrderItems[]",
            additionalFields: "StoreAdditionalFieldAnswerValues[]?",
            externalReference: "string?",
            cancellationReason: "string?",
            cancellationObs: "string?",
            isPostPaid: "bool"
        },
        BaseStoreDeliveryFeeRadius: {
            value: "uint",
            perKm: "uint"
        },
        EditStoreDeliveryFeeRadius: {
            value: "uint",
            perKm: "uint",
            id: "string?",
            storeId: "string"
        },
        StoreDeliveryFeeRadius: {
            value: "uint",
            perKm: "uint",
            id: "string",
            storeId: "string"
        },
        BaseStoreBasic: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?"
        },
        BaseStoreV1Deprecated: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            attendanceRadius: "float?",
            deliveryFeePerKm: "uint?",
            fixedDeliveryFee: "uint?",
            deliveryType: "DeliveryType[]?"
        },
        StableStoreBase: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            attendanceRadius: "float",
            deliveryFeePerKm: "uint?",
            fixedDeliveryFee: "uint?",
            deliveryType: "DeliveryType[]",
            logoUrl: "string?"
        },
        BaseStoreV2: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            attendanceRadius: "float",
            deliveryFeePerKm: "uint?",
            fixedDeliveryFee: "uint?",
            deliveryType: "DeliveryType[]",
            logoUrl: "string?",
            deliveryFeeRadius: "BaseStoreDeliveryFeeRadius[]?",
            deliveryAvgWaitTime: "uint",
            dineInAvgWaitTime: "uint"
        },
        StoreV1Deprecated: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            id: "string",
            logoUrl: "string?",
            attendanceRadius: "uint",
            deliveryFeePerKm: "uint",
            fixedDeliveryFee: "uint",
            isManualMenu: "bool",
            isOrganization: "bool",
            additonalFields: "StoreAdditionalFields[]?",
            deliveryType: "DeliveryType[]?"
        },
        StoreBaseForStore: {
            name: "string",
            fantasyName: "string?",
            address: "Address?",
            attendanceRadius: "uint",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            phone: "string?",
            deliveryType: "DeliveryType[]?",
            whatsapp: "string?",
            bannerUrl: "string?"
        },
        StoreV2: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            attendanceRadius: "float",
            deliveryFeePerKm: "uint?",
            fixedDeliveryFee: "uint?",
            deliveryType: "DeliveryType[]",
            logoUrl: "string?",
            id: "string",
            deliveryFeeRadius: "StoreDeliveryFeeRadius[]?",
            createdAt: "datetime?",
            deliveryAvgWaitTime: "uint",
            dineInAvgWaitTime: "uint"
        },
        CompletePaymentMethod: {
            method: "PaymentMethod",
            brand: "string?"
        },
        StoreBySlug: {
            storeId: "uuid",
            name: "string",
            slug: "string"
        },
        PartnerDetails: {
            id: "string",
            name: "string",
            email: "string?",
            primaryColor: "string?",
            secondaryColor: "string?",
            logoUrl: "string?",
            poweredByUrl: "string?",
            url: "string?"
        },
        StableBasePlan: {
            name: "string",
            description: "string",
            typePayInterval: "TimeInterval",
            termsOfUse: "string?",
            paymentLink: "string?",
            partnerId: "string?"
        },
        BasePlan: {
            name: "string",
            description: "string",
            typePayInterval: "TimeInterval",
            termsOfUse: "string?",
            paymentLink: "string?",
            partnerId: "string?",
            value: "uint",
            isActive: "bool",
            isAddon: "bool",
            expiresAt: "date",
            childrenIds: "string[]?"
        },
        StableBaseStorePlan: {
            contractedAmount: "uint",
            payInterval: "TimeInterval",
            startAgreementAt: "datetime",
            endAgreementAt: "datetime",
            status: "StableBaseStorePlanStatus"
        },
        AgreementDetails: {
            contractedAmount: "uint",
            payInterval: "TimeInterval",
            startAgreementAt: "datetime",
            endAgreementAt: "datetime",
            status: "StableBaseStorePlanStatus",
            type: "AgreementDetailsType",
            id: "string"
        },
        BaseStorePlan: {
            contractedAmount: "uint",
            payInterval: "TimeInterval",
            startAgreementAt: "datetime",
            endAgreementAt: "datetime",
            status: "StableBaseStorePlanStatus",
            planId: "string",
            storeId: "string",
            statusReason: "string?"
        },
        BaseFeature: {
            name: "string",
            slug: "string",
            description: "string?"
        },
        Feature: {
            name: "string",
            slug: "string",
            description: "string?",
            id: "string"
        },
        Menu: {
            id: "string",
            name: "string",
            isCurrentMenu: "bool",
            workingPeriods: "MenuWorkingPeriod[]",
            menuType: "MenuType?"
        },
        MenuWorkingPeriod: {
            dayOfWeek: "MenuWorkingPeriodDayOfWeek",
            startTime: "string",
            endTime: "string"
        },
        ExportProductsMenu: {
            filename: "string",
            data: "bytes"
        },
        BaseMenuProduct: {
            id: "uuid",
            price: "uint"
        },
        ProductsPayload: {
            products: "BaseMenuProduct[]",
            menuId: "uuid"
        },
        ProductOnMenu: {
            menuId: "uuid",
            productPrice: "uint"
        },
        LiteEditProduct: {
            id: "string",
            name: "string",
            description: "string?",
            sku: "string",
            category: "string",
            baseValue: "uint?"
        },
        EditError: {
            id: "string",
            name: "string",
            error: "string"
        },
        ResultMultipleProductsFailed: {
            productName: "string",
            message: "string"
        },
        ResultMultipleProducts: {
            success: "Product[]",
            failed: "ResultMultipleProductsFailed[]"
        },
        Image: {
            id: "string?",
            data: "bytes?",
            url: "string?"
        },
        BaseCategory: {
            name: "string",
            order: "uint",
            parentId: "string?",
            image: "Image?"
        },
        Category: {
            id: "string",
            name: "string",
            order: "uint",
            parentId: "string?",
            image: "Image?"
        },
        CategoryWithSubCategoriesSubCategoriesSubCategories: {
            id: "string",
            name: "string",
            order: "uint",
            parentId: "string?",
            image: "Image?"
        },
        CategoryWithSubCategoriesSubCategories: {
            id: "string",
            name: "string",
            order: "uint",
            parentId: "string?",
            image: "Image?",
            subCategories: "CategoryWithSubCategoriesSubCategoriesSubCategories[]"
        },
        CategoryWithSubCategories: {
            id: "string",
            name: "string",
            order: "uint",
            parentId: "string?",
            image: "Image?",
            subCategories: "CategoryWithSubCategoriesSubCategories[]"
        },
        BaseCategoryV2: {
            name: "string",
            order: "uint?",
            parentId: "string?",
            image: "Image?"
        },
        StoreBaseData: {
            name: "string",
            fantasyName: "string?",
            address: "Address?",
            attendanceRadius: "uint",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            phone: "string?",
            deliveryType: "DeliveryType[]?",
            whatsapp: "string?",
            bannerUrl: "string?",
            deliveryFeePerKm: "uint",
            fixedDeliveryFee: "uint",
            hideDeliveryTime: "bool?"
        },
        Store: {
            name: "string",
            cnpj: "string",
            fantasyName: "string?",
            address: "Address",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            hideDeliveryTime: "bool?",
            slug: "string",
            phone: "string?",
            whatsapp: "string?",
            bannerUrl: "string?",
            requiredCpf: "bool?",
            timezone: "string?",
            id: "string",
            logoUrl: "string?",
            attendanceRadius: "uint",
            deliveryFeePerKm: "uint",
            fixedDeliveryFee: "uint",
            isManualMenu: "bool",
            isOrganization: "bool",
            additonalFields: "StoreAdditionalFields[]?",
            deliveryType: "DeliveryType[]?",
            partnerLogoUrl: "string?",
            partnerPoweredByUrl: "string?",
            partnerUrl: "string?",
            lowestDeliveryValueByRadius: "uint?"
        },
        EditStoreV2: {
            name: "string",
            fantasyName: "string?",
            address: "Address?",
            attendanceRadius: "uint",
            primaryColor: "string?",
            secondaryColor: "string?",
            avgWaitTime: "uint",
            phone: "string?",
            deliveryType: "DeliveryType[]?",
            whatsapp: "string?",
            bannerUrl: "string?",
            deliveryFeePerKm: "uint?",
            fixedDeliveryFee: "uint?",
            hideDeliveryTime: "bool?",
            deliveryFeeRadius: "EditStoreDeliveryFeeRadius[]?",
            deliveryAvgWaitTime: "uint",
            dineInAvgWaitTime: "uint"
        },
        StoreStatus: {
            vendor: "Vendor",
            status: "bool"
        },
        ExternalStoreIntegration: {
            externalStoreId: "string",
            name: "string",
            createdAt: "datetime"
        },
        GetStoreExternalIntegrationResponseDto: {
            type: "ExternalStoreType",
            stores: "ExternalStoreIntegration[]"
        },
        UpsertManyStoreExternalIntegrationsRequestDto: {
            externalIds: "string[]",
            type: "ExternalStoreType"
        },
        ExternalStoreIntegrationProductDto: {
            externalId: "string?",
            externalStoreId: "string",
            name: "string",
            description: "string?",
            price: "uint",
            sku: "string?",
            isActive: "bool",
            imageUrl: "string?"
        },
        ExternalStoreIntegrationSectionProductDto: {
            externalId: "string?",
            externalStoreId: "string",
            name: "string",
            description: "string?",
            index: "uint",
            price: "uint",
            sku: "string?",
            isActive: "bool"
        },
        ExternalStoreIntegrationSection: {
            externalId: "string",
            name: "string",
            index: "uint",
            isActive: "bool",
            min: "uint",
            max: "uint",
            products: "ExternalStoreIntegrationSectionProductDto[]"
        },
        ExternalStoreIntegrationProductWithSectionsDto: {
            externalId: "string?",
            externalStoreId: "string",
            name: "string",
            description: "string?",
            price: "uint",
            sku: "string?",
            isActive: "bool",
            imageUrl: "string?",
            sections: "ExternalStoreIntegrationSection[]"
        },
        ExternalStoreIntegrationCategoryDto: {
            externalId: "string",
            externalStoreId: "string",
            name: "string",
            sku: "string?",
            products: "ExternalStoreIntegrationProductWithSectionsDto[]"
        },
        GetManyExternalStoreIntegrationCategoriesResponseDto: {
            categories: "ExternalStoreIntegrationCategoryDto[]",
            totalOfProducts: "uint"
        },
        UpdateExternalProductSkuRequestDto: {
            externalProductId: "string?",
            currentSku: "string?",
            newSku: "string"
        },
        BaseRecipientManagingPartner: {
            name: "string",
            email: "string",
            documentNumber: "string"
        },
        RecipientManagingPartner: {
            name: "string",
            email: "string",
            documentNumber: "string",
            createdAt: "datetime",
            recipientId: "string",
            id: "string"
        },
        UpdateRecipientManagingPartner: {
            name: "string",
            email: "string",
            documentNumber: "string",
            id: "string?"
        },
        BaseRecipient: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?"
        },
        Recipient: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?",
            managingPartners: "RecipientManagingPartner[]",
            createdAt: "datetime",
            storeId: "string",
            id: "string"
        },
        NewRecipient: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?",
            managingPartners: "BaseRecipientManagingPartner[]"
        },
        NewRecipientV2: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?",
            managingPartners: "BaseRecipientManagingPartner[]?"
        },
        UpdateRecipient: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?",
            managingPartners: "UpdateRecipientManagingPartner[]",
            storeId: "string",
            id: "string"
        },
        UpdateRecipientV2: {
            name: "string",
            bankName: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            email: "email?",
            documentNumber: "string",
            pagarmeRecipientAcquirerId: "string?",
            managingPartners: "UpdateRecipientManagingPartner[]?",
            storeId: "string",
            id: "string"
        },
        AcquirerRecipient: {
            id: "string",
            name: "string",
            bankCode: "string",
            branch: "string",
            branchDigit: "string?",
            typeAccount: "TypeAccount?",
            account: "string",
            accountDigit: "string",
            typeDocument: "TypeDocument",
            documentNumber: "string"
        },
        ReceivableAmounts: {
            totalAmount: "uint",
            totalAvailable: "uint",
            totalToReceive: "uint",
            receivableDate: "date"
        },
        RecipientAcquirerResponse: {
            type: "RecipientAcquirerResponseType",
            message: "string[]"
        },
        VendorReport: {
            vendor: "Vendor",
            value: "uint",
            tip: "uint",
            deliveryFee: "uint"
        },
        OrderReport: {
            orderId: "string",
            customerName: "string",
            status: "OrderStatus",
            value: "uint",
            deliveryFee: "uint",
            deliveryType: "DeliveryType",
            tip: "uint",
            date: "datetime",
            channel: "DeliveryChannel",
            vendor: "Vendor",
            obs: "string?",
            externalReference: "string?",
            reference: "uint",
            subTotal: "uint",
            discountValue: "uint",
            customerPhone: "string?",
            paymentMethods: "string[]",
            discountValueDeliveryFee: "uint?",
            discountValueDeliveryFeeMarketplace: "uint?",
            discountValueMarketplace: "uint?"
        },
        ProductOrderReport: {
            productId: "string",
            sku: "string",
            deliveryOrderId: "string",
            name: "string",
            baseUnitPrice: "uint",
            totalUnitPrice: "uint",
            count: "uint",
            obs: "string?"
        },
        GeneralProductOrderReport: {
            productId: "string",
            sku: "string",
            name: "string",
            count: "uint",
            totalValue: "uint"
        },
        GeneralProductOrderReportV2: {
            productId: "string",
            sku: "string",
            name: "string",
            count: "uint",
            totalValue: "uint",
            baseValue: "uint",
            vendor: "Vendor"
        },
        SalesAmount: {
            currentSalesAmount: "uint",
            currentAverageTicket: "uint",
            previousSalesAmount: "uint",
            previousAverageTicket: "uint",
            growthRateSalesAmount: "float",
            growthRateAverageTicket: "float"
        },
        BestSellingProductsReport: {
            id: "string",
            productName: "string",
            amount: "uint"
        },
        ProductAverageTicket: {
            productName: "string",
            averageTicket: "uint"
        },
        BestSellingProductFromVendor: {
            ifood: "BestSellingProductsReport[]?",
            rappi: "BestSellingProductsReport[]?",
            ubereats: "BestSellingProductsReport[]?"
        },
        SalesFromVendor: {
            vendor: "string",
            amount: "uint",
            amountLiquid: "uint",
            fees: "uint",
            percentage: "float"
        },
        RevenuePerDayReport: {
            weekday: "uint",
            currentAmount: "uint",
            previousAmount: "uint"
        },
        RevenuePerHourReport: {
            hour: "uint",
            currentAmount: "uint",
            previousAmount: "uint"
        },
        PaginationRequestDto: {
            currentPage: "uint",
            perPage: "uint"
        },
        PaginationResponseDto: {
            currentPage: "uint",
            perPage: "uint",
            total: "uint",
            lastPage: "uint"
        },
        GetCombinedOrdersReportRequestDtoPeriod: {
            since: "datetime",
            until: "datetime"
        },
        GetCombinedOrdersReportRequestDto: {
            reference: "string?",
            vendors: "Vendor[]?",
            statuses: "OrderStatus[]?",
            period: "GetCombinedOrdersReportRequestDtoPeriod?"
        },
        CombinedOrderReportPaymentDto: {
            method: "PaymentMethod",
            brand: "string?",
            change: "uint?",
            partnerPaymentId: "string?",
            deliveryFee: "uint",
            discountValue: "uint",
            discountValueMarketplace: "uint?",
            discountValueDeliveryFee: "uint?",
            discountValueDeliveryFeeMarketplace: "uint?",
            tip: "uint",
            subTotal: "uint",
            total: "uint"
        },
        CombinedOrderReportSubItemDto: {
            id: "string",
            sku: "string",
            name: "string",
            sectionId: "string?",
            sectionName: "string?",
            pricing: "CombinedOrderReportSubItemDtoPricing",
            price: "uint",
            count: "uint"
        },
        CombinedOrderReportItemDto: {
            id: "string",
            sku: "string",
            name: "string",
            count: "uint",
            baseUnitPrice: "uint",
            totalUnitPrice: "uint",
            obs: "string?",
            subItems: "CombinedOrderReportSubItemDto[]"
        },
        GetCombinedOrderReportDto: {
            id: "string",
            reference: "uint",
            obs: "string?",
            date: "datetime",
            finishedAt: "datetime?",
            value: "uint",
            channel: "DeliveryChannel",
            status: "OrderStatus",
            vendor: "Vendor",
            deliveryType: "DeliveryType",
            customerName: "string",
            customerAddress: "string",
            payment: "CombinedOrderReportPaymentDto?",
            items: "CombinedOrderReportItemDto[]"
        },
        GetCombinedOrdersReportResponseDto: {
            items: "GetCombinedOrderReportDto[]",
            totalValue: "uint",
            pagination: "PaginationResponseDto"
        },
        IFoodIntegration: {
            storeId: "string",
            ifoodId: "string",
            isActive: "bool",
            ifoodName: "string?",
            deliveryByIFood: "bool",
            automaticallyAcceptedValue: "uint?"
        },
        StorePlan: {
            name: "string",
            description: "string",
            typePayInterval: "TimeInterval",
            termsOfUse: "string?",
            paymentLink: "string?",
            partnerId: "string?",
            id: "string",
            agreementDetails: "AgreementDetails",
            features: "Feature[]"
        },
        Plan: {
            name: "string",
            description: "string",
            typePayInterval: "TimeInterval",
            termsOfUse: "string?",
            paymentLink: "string?",
            partnerId: "string?",
            id: "string",
            statusAtStore: "string"
        },
        PlanComplete: {
            name: "string",
            description: "string",
            typePayInterval: "TimeInterval",
            termsOfUse: "string?",
            paymentLink: "string?",
            partnerId: "string?",
            id: "string",
            statusAtStore: "string",
            features: "Feature[]",
            iconUrl: "string?",
            value: "uint",
            startAgreementAt: "datetime?",
            endAgreementAt: "datetime?"
        },
        TrialPlanPeriod: {
            payInterval: "TrialPlanPeriodPayInterval",
            count: "uint"
        },
        TrialPlan: {
            planId: "string",
            period: "TrialPlanPeriod"
        },
        AdditionalFieldType: [
            "number",
            "text",
            "select",
            "date"
        ],
        MountableSectionPricing: [
            "sum",
            "average",
            "higher"
        ],
        PaymentMethod: [
            "creditCardInPlace",
            "debitCardOnline",
            "debitCardInPlace",
            "cash",
            "creditCardOnline",
            "voucherInPlace",
            "ame",
            "pix"
        ],
        Vendor: [
            "rappi",
            "coala",
            "ifood",
            "ubereats"
        ],
        OrderStatus: [
            "placed",
            "scheduled",
            "integrated",
            "confirmed",
            "dispatched",
            "concluded",
            "cancelled",
            "ready",
            "pendingPayment",
            "pickupAreaAssigned"
        ],
        DeliveryType: [
            "dineIn",
            "pickUp",
            "delivery",
            "deliveryByPartner",
            "deliveryByRestaurant",
            "deliveryByMarketplace",
            "onlineFicha",
            "onlineTab",
            "viewMenu"
        ],
        DeliveryChannel: [
            "phone",
            "whatsapp",
            "marketplace",
            "whitelabel"
        ],
        DeliveryOrderCustomerDocumentType: [
            "cpf",
            "phone",
            "unknown"
        ],
        DeliveryOrderItemsSubItemsPricing: [
            "sum",
            "average",
            "higher"
        ],
        ExternalStoreType: [
            "iFood",
            "rappi"
        ],
        TimeInterval: [
            "weekly",
            "monthly",
            "yearly"
        ],
        StableBaseStorePlanStatus: [
            "active",
            "suspended",
            "inactive"
        ],
        AgreementDetailsType: [
            "agreement",
            "trial",
            "partner"
        ],
        MenuType: [
            "delivery",
            "table"
        ],
        MenuWorkingPeriodDayOfWeek: [
            "mon",
            "tue",
            "wed",
            "thu",
            "fri",
            "sat",
            "sun",
            "hol"
        ],
        TypeAccount: [
            "conta_corrente",
            "conta_poupanca",
            "conta_corrente_conjunta",
            "conta_poupanca_conjunta"
        ],
        TypeDocument: [
            "cpf",
            "cnpj"
        ],
        RecipientAcquirerResponseType: [
            "error",
            "success"
        ],
        GetCombinedOrdersReportOrderBy: [
            "reference",
            "date",
            "status",
            "value"
        ],
        GetCombinedOrdersReportOrderDirection: [
            "asc",
            "desc"
        ],
        CombinedOrderReportSubItemDtoPricing: [
            "sum",
            "average",
            "higher"
        ],
        TrialPlanPeriodPayInterval: [
            "weekly",
            "monthly",
            "yearly"
        ]
    }
} as const;
