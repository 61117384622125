export const deliveryReport = {
	title: "Delivery Reports",
	description: "Monitor and analyze your establishment's delivery orders.",
	tabs: {
		general: "General Report",
	},
	selectPeriod: "Select a period",
	periodOptions: {
		today: "Today",
		last7days: "Last 7 days",
		last15days: "Last 15 days",
		last30days: "Last 30 days",
		last90days: "Last 90 days",
		custom: "Select a period",
	},
	generalTab: {
		export: "Export",
		filtersToolbar: {
			search: "Search order #",
			vendors: "Vendors",
			vendorsPlaceholder: "Select vendors",
			status: "Status",
			statusPlaceholder: "Select status",
			period: "Period",
			periodPlaceholder: "Select period",
			back: "Back",
			apply: "Apply",
		},
		table: {
			columns: {
				vendor: "Vendor",
				reference: "Order #",
				status: "Status",
				date: "Date",
				orderCreatedAt: "Order Time",
				orderEndAt: "Order Completed",
				value: "Total",
				viewMore: "View More",
			},
			summary: "Period total",
			seeMore: "See more details",
		},
		vendorType: {
			rappi: "Rappi",
			coala: "Coala",
			ifood: "iFood",
			ubereats: "Ubereats",
		},
		orderStatus: {
			cancelled: "Cancelled",
			concluded: "Concluded",
			confirmed: "Confirmed",
			dispatched: "Dispatched",
			integrated: "Integrated",
			pendingPayment: "Payment pending",
			pickupAreaAssigned: "Customer on site",
			placed: "Created",
			ready: "Ready",
			scheduled: "Scheduled",
		},
		orderDetailsDrawer: {
			orderRange:
				"Order placed on {{startDate}} at {{startHour}}<br>Completed at {{endHour}}",
			order: "Order",
			paymentDetails: "FINANCIAL DETAILS",
			paymentMethod: "Payment method",
			subTotal: "Sale subtotal",
			discountAndImpost: "Fees and discounts",
			totalReceive: "Total to receive",
			discountValue: "{{value}} - Discount coupon",
			discountValueDeliveryFee: "{{value}} - Delivery Fee",
			discountValueDeliveryFeeMarketplace: "{{value}} - Marketplace Delivery Fee",
			discountValueMarketplace: "{{value}} - Marketplace",
			paymentMethodType: {
				creditCardInPlace: "Credit card",
				creditCardOnline: "Credit card",
				debitCardOnline: "Debit card",
				debitCardInPlace: "Debit card",
				cash: "Cash",
				voucherInPlace: "Voucher",
				ame: "Ame",
				pix: "Pix",
			},
		},
	},
	orderItemsTable: {
		columns: {
			count: "Qty.",
			name: "Item",
			baseUnitPrice: "Unit Price",
			totalUnitPrice: "Subtotal",
		},
		summary: "Subtotal",
		totalReceive: "TOTAL TO RECEIVE",
	},
};
