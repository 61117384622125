import * as React from "react";
import { observer } from "mobx-react-lite";
import { NavBar as NavBarV2 } from "#components/nav-bars-shared/nav-bar-v2";
import NavBar, { NavBarRoute } from "#components/nav-bars-shared/nav-bar";
import RootStore from "#stores/index";
import { isMain } from "#configuration/config";
import {
	NavBarRouteV2,
	RouteItemProps,
	RouteItemPropsV2,
	useNavigationWithRestriction,
} from "../navigation-with-permissions";
import { useTranslation } from "react-i18next";
import { useSandwichMenu } from "#structure/providers/sandwich-menu";
import {
	barReportsByPlaceTabs,
	entranceReportsByPlaceTabs,
	financialReportsByPlaceTabs,
	fiscalTabs,
	multivendorPlaceTabs,
	operationsReportPlaceTabs,
	placeStorageReports,
	placeStorageTabs,
	reserveTabs,
	tableReportsByPlaceTabs,
} from "#resources/helpers/tabs";
import { extractAlternativeUrlsFromTabs } from "../extract-from-tabs";
import { useModularization } from "../use-modularization";
import { usePermission } from "#resources/hooks";

interface IProps {
	placeId: string;
}

function Navbar(props: IProps) {
	const baseUrl = `/place/${props.placeId}`;
	const { placeStore, authStore } = React.useContext(RootStore);
	const { t } = useTranslation("nav", { keyPrefix: "navbar.place" });
	const { hasPermissionGrouped } = usePermission();

	const isPlaceForeign = placeStore.isCurrentPlaceInternational;
	const hasDashboardFiscalFeature = placeStore.hasFeature("hasFiscal");
	const hasRappi = placeStore.hasFeature("rappiPay");

	const {
		isOpenSandwichMenu,
		onChangeIsOpenSandwichMenu,
		currentSelectedMenu,
	} = useSandwichMenu();

	React.useEffect(() => {
		placeStore.getPlaceFeatures(props.placeId);
	}, []);

	const isBetaZigConta =
		props.placeId === "6053ecf3-291d-4a4a-9787-2baa997cb1d5" ||
		props.placeId === "e4e8bc18-167c-4a6d-9f7a-229c82b787fe";

	const isGlRelayPlace =
		props.placeId === "161c48e2-2697-4dbf-8138-8f2b88f0b949" ||
		props.placeId === "2e787ece-bc23-473c-a1b7-26e2ef1b76de" ||
		props.placeId === "8068f439-8152-4158-8bb3-c8c3ae8d5252" ||
		props.placeId === "1fc4b781-c8e7-44a9-986d-ab22c725afce";

	const { showMenuV2 } = useModularization();

	const fiscalTabsList = fiscalTabs(
		props.placeId,
		hasDashboardFiscalFeature,
		authStore.isZigpayEmployee,
		isPlaceForeign,
	);

	const tabsGroupedPermissions = hasPermissionGrouped([
		"seeBonusReports",
		"seeCashierReports",
		"seeFinanceReports",
		"seeOpenedBillsReport",
		"seeProductsSoldReport",
		"seeRefundReports",
		"seeSellsByWaiterReport",
		"seeBarReports",
	]);

	const multivendorPlaceTabsList = multivendorPlaceTabs(props.placeId);

	const reserveTabsList = reserveTabs(props.placeId);

	const storageTabsList = placeStorageTabs({ placeId: props.placeId });

	const storageReportTabsList = placeStorageReports({
		placeId: props.placeId,
		hasCmv: placeStore.hasFeature("cmv"),
		hasFinancial: placeStore.hasFeature("backoffice"),
	});

	const financialReportTabs = financialReportsByPlaceTabs(
		props.placeId,
		tabsGroupedPermissions,
		{
			hasRappi,
		},
	);

	const barReportTabs = barReportsByPlaceTabs(props.placeId, tabsGroupedPermissions, {
		burnPaperTickets: placeStore.hasFeature("burnPaperTickets"),
	});

	const ticketReportTabs = entranceReportsByPlaceTabs(
		props.placeId,
		tabsGroupedPermissions.seeFinanceReports,
	);

	const tableReportTabs = tableReportsByPlaceTabs(props.placeId);

	const { hasPermission } = usePermission();
	const hasSeeOnlyOpenedEventsPermission = hasPermission("seeOnlyOpenedEvents");
	const canSeeReports = authStore.isZigpayEmployee || !hasSeeOnlyOpenedEventsPermission;
	const operationsReportTabs = operationsReportPlaceTabs(props.placeId);

	const hasMenuManagementPermission = hasPermission("menuManagement");
	const hasMenuManagement = hasMenuManagementPermission && placeStore.hasFeature("menu");

	const routesV1: RouteItemProps[] = [
		{
			icon: "events",
			label: t("events"),
			url: `${baseUrl}/`,
		},
		{
			icon: "business-inteligence",
			label: t("businessInteligence"),
			url: `${baseUrl}/business-intelligence`,
			permissions: ["seeFinanceReports", "seeBusinessIntelligencePage"],
		},
		{
			icon: "users",
			label: t("pdvTeam"),
			url: `${baseUrl}/employees`,
		},
		{
			icon: "products",
			label: t("productAndMenu"),
			url: `${baseUrl}/${hasMenuManagement ? "manage-menu" : "products"}`,
			permissions: ["seeProductsPage"],
		},
		{
			icon: "clients",
			label: t("clients"),
			url: `${baseUrl}/clients`,
			permissions: ["seePlaceCustomersPage"],
		},
		{
			icon: "tickets",
			label: t("tickets"),
			url: `${baseUrl}/entrance`,
		},
		{
			icon: "list-multiple",
			label: t("guestList"),
			url: `${baseUrl}/guest-lists`,
		},
		{
			icon: "reserve",
			label: t("reserve"),
			url: `${baseUrl}/reserve/reserve-manager`,
			hide: isMain,
		},
		{
			icon: "financial-report",
			label: t("financialReport"),
			url: `${baseUrl}/financial-report`,
			permissions: ["seeFinancialReportPage"],
			hide: !canSeeReports,
		},
		{
			icon: "bar-report",
			label: t("barReports"),
			url: `${baseUrl}/bar-reports`,
			permissions: ["seeBarReports"],
			hide: !canSeeReports,
		},
		{
			icon: "table-report",
			label: t("tableReports"),
			url: `${baseUrl}/table-reports`,
			placeFeature: "hasMesa",
			hide: !canSeeReports,
		},
		{
			icon: "tickets-report",
			label: t("ticketsReport"),
			url: `${baseUrl}/entrance-reports`,
			hide: !canSeeReports,
		},
		{
			icon: "report",
			label: t("prechargeReports"),
			url: `${baseUrl}/precharge-report`,
			hide: isMain,
		},
		{
			icon: "report",
			label: t("operationReport"),
			url: `${baseUrl}/operation-place-transactions-report`,
		},
		{
			icon: "tax-data",
			label: t("transferReport"),
			url: `${baseUrl}/transfer-report`,
			hide: !isGlRelayPlace,
		},
		{
			icon: "new-storage",
			label: t("storage"),
			url: `${baseUrl}/storage-list`,
			permissions: ["seeStoragePage"],
			placeFeature: "storage",
		},
		{
			icon: "storage-reports",
			label: t("storageReports"),
			url: `${baseUrl}/profit-margin-report`,
			permissions: ["seeStoragePage"],
			tagType: "new",
			placeFeature: "storage",
		},
		{
			icon: "account-statement",
			label: t("extract"),
			url: `${baseUrl}/extract`,
			permissions: ["seeExtractPage", "seeExtract"],
		},
		{
			icon: "tax",
			label: t("invoices"),
			url: `${baseUrl}/invoices`,
			permissions: ["seeInvoicesPage"],
		},
		{
			icon: "promotions",
			label: t("promotions"),
			url: `${baseUrl}/promotions`,
		},
		{
			icon: "pos-machine",
			label: t("pos"),
			url: `${baseUrl}/pos`,
			hide: isMain,
		},
		{
			icon: "cash-flow",
			label: t("cashFlow"),
			url: `${baseUrl}/financeiro`,
			placeFeature: "backoffice",
		},
		{
			icon: "zig-account",
			label: t("zigAccount"),
			url: `${baseUrl}/zig-conta`,
			hide: !isBetaZigConta,
		},
		{
			icon: "multivendor",
			label: t("multivendor"),
			url: `${baseUrl}/multivendor-extract`,
			orgFeaturesIds: "multivendor",
		},
		{
			icon: "cash-flow",
			label: t("precharge"),
			url: `${baseUrl}/precharge`,
		},
		{
			icon: "whats-up",
			label: t("whatsup"),
			url: `${baseUrl}/qual-boa`,
			placeFeature: "whatsUp",
		},
		{
			icon: "password-card",
			label: t("passwordCard"),
			url: `${baseUrl}/password-card`,
		},
		{
			icon: "audits",
			label: t("audits"),
			url: `${baseUrl}/audits`,
		},
		{
			icon: "contract",
			label: t("contracts"),
			url: `${baseUrl}/contracts`,
		},
		{
			icon: "pos-machine",
			label: t("tagGroups"),
			url: `${baseUrl}/tag-groups`,
			placeFeature: "enableRechargeByZigCode",
		},
		{
			icon: "recharge-management",
			label: t("rechargeManagement"),
			url: `${baseUrl}/recharge-management`,
			permissions: ["manageRecharge"],
		},
		{
			icon: "settings",
			label: t("settings"),
			url: `${baseUrl}/settings`,
		},
	];

	const routesV2: RouteItemPropsV2[] = [
		{
			icon: "events",
			label: t("events"),
			url: `${baseUrl}`,
			alternativeUrls: [`${baseUrl}/`],
		},
		{
			icon: "users",
			label: t("pdvTeam"),
			url: `${baseUrl}/employees`,
		},
		{
			icon: "business-intelligence-v2",
			label: t("businessInteligence"),
			url: `${baseUrl}/business-intelligence`,
			permissions: ["seeFinanceReports", "seeBusinessIntelligencePage"],
			alternativeUrls: [
				`${baseUrl}/business-intelligence#financeiro`,
				`${baseUrl}/business-intelligence#funcionarios`,
				`${baseUrl}/business-intelligence#clientes`,
			],
		},
		{
			icon: "tickets-v2",
			label: t("tickets"),
			url: `${baseUrl}/entrance`,
		},
		{
			label: t("productAndMenu"),
			icon: "packages",
			url: `${baseUrl}/${hasMenuManagement ? "manage-menu" : "products"}`,
			permissions: ["seeProductsPage"],
			alternativeUrls: [
				`${baseUrl}/combos`,
				`${baseUrl}/supplies`,
				`${baseUrl}/beer-dispenser`,
				`${baseUrl}/grab-and-go`,
				`${baseUrl}/manage-menu`,
				`${baseUrl}/cmv`,
				`${baseUrl}/personalization-settings`,
			],
		},
		{
			label: t("bars"),
			icon: "bars",
			url: `${baseUrl}/bars`,
		},
		{
			label: t("kds"),
			icon: "kds",
			submenus: [
				{
					label: t("kdsConfig"),
					url: `${baseUrl}/kds`,
				},
				{
					label: t("kdsReport"),
					url: `${baseUrl}/kds/reports`,
				},
			],
		},
		{
			icon: "reports",
			label: t("reports"),
			hide: !canSeeReports,
			submenus: [
				{
					label: t("financialReport"),
					permissions: ["seeFinancialReportPage"],
					url: `${baseUrl}/financial-report#/FinancialReport`,
					alternativeUrls: extractAlternativeUrlsFromTabs(financialReportTabs),
				},
				{
					label: t("salesReport"),
					url: `${baseUrl}/bar-reports#/SoldProducts`,
					permissions: ["seeBarReports"],
					alternativeUrls: extractAlternativeUrlsFromTabs(barReportTabs),
					tagType: "new",
				},
				{
					label: t("ticketsReport"),
					url: `${baseUrl}/entrance-reports#/boxOfficeBilling`,
					alternativeUrls: extractAlternativeUrlsFromTabs(ticketReportTabs),
				},
				{
					label: t("tableReports"),
					placeFeature: "hasMesa",
					url: `${baseUrl}/table-reports#general`,
					alternativeUrls: extractAlternativeUrlsFromTabs(tableReportTabs),
				},
				{
					label: t("operationReport"),
					url: `${baseUrl}/operation-place-transactions-report`,
					alternativeUrls: extractAlternativeUrlsFromTabs(operationsReportTabs),
				},
				{
					label: t("transferReport"),
					url: `${baseUrl}/transfer-report`,
					hide: !isGlRelayPlace,
				},
			],
		},
		{
			icon: "pre-charge",
			label: t("preChargeLabel"),
			submenus: [
				{
					label: t("prechargeReports"),
					url: `${baseUrl}/precharge-report`,
					hide: isMain,
				},
				{
					label: t("precharge"),
					url: `${baseUrl}/precharge`,
				},
			],
		},
		{
			icon: "new-storage",
			label: t("storage"),
			placeFeature: "storage",
			submenus: [
				{
					label: t("inventoryControl"),
					permissions: ["seeStoragePage"],
					url: `${baseUrl}/storage-list`,
					alternativeUrls: extractAlternativeUrlsFromTabs(storageTabsList),
				},
				{
					label: t("storageReports"),
					permissions: ["seeStoragePage"],
					url: `${baseUrl}/profit-margin-report`,
					alternativeUrls: extractAlternativeUrlsFromTabs(storageReportTabsList),
				},
			],
		},
		{
			icon: "multivendor-v2",
			label: t("multivendor"),
			orgFeaturesIds: "multivendor",
			url: `${baseUrl}/multivendor-extract`,
			alternativeUrls: extractAlternativeUrlsFromTabs(multivendorPlaceTabsList),
		},
		{
			icon: "fiscal",
			label: t("invoices"),
			url: `${baseUrl}/invoices`,
			permissions: ["seeInvoicesPage"],
			alternativeUrls: extractAlternativeUrlsFromTabs(fiscalTabsList),
		},
		{
			icon: "receptive",
			label: t("receptive"),
			submenus: [
				{
					label: t("guestList"),
					url: `${baseUrl}/guest-lists`,
					alternativeUrls: [`${baseUrl}/guest-lists-report`],
				},
				{
					label: t("reserve"),
					url: `/place/${props.placeId}/reserve/reserve-manager`,
					alternativeUrls: extractAlternativeUrlsFromTabs(reserveTabsList),
					hide: isMain,
				},
			],
		},
		{
			icon: "promotions",
			label: t("promotions"),
			url: `${baseUrl}/promotions`,
			alternativeUrls: [`${baseUrl}/discount-categories`, `${baseUrl}/bonus-categories`],
		},
		{
			icon: "financial",
			label: t("extract"),
			url: `${baseUrl}/extract#consolidated`,
			alternativeUrls: [
				`${baseUrl}/extract`,
				`${baseUrl}/extract#financial`,
				`${baseUrl}/extract#withdraws`,
				`${baseUrl}/extract#devolution`,
				`${baseUrl}/extract#adjustments`,
				`${baseUrl}/extract#accounts`,
				`${baseUrl}/extract#flow`,
				`${baseUrl}/extract#locationNotes`,
				`${baseUrl}/extract#debitNotes`,
			],
		},
		{
			icon: "delivery",
			label: t("delivery"),
			placeFeature: "delivery",
			submenus: [
				{
					label: t("deliveryReport"),
					url: `${baseUrl}/delivery/report`,
				},
				{
					label: t("deliveryMenus"),
					url: `${baseUrl}/delivery/menus`,
				},
				{
					label: t("deliverySettings"),
					url: `${baseUrl}/delivery/settings`,
				},
			],
		},
		{
			icon: "pos-machine",
			label: t("pos"),
			url: `${baseUrl}/pos`,
			hide: isMain,
		},
		{
			icon: "cash-flow",
			label: t("cashFlow"),
			url: `${baseUrl}/financeiro`,
			alternativeUrls: [
				`${baseUrl}/financeiro/fornecedores`,
				`${baseUrl}/financeiro/accounts/payable`,
				`${baseUrl}/financeiro/accounts/receivable`,
				`${baseUrl}/financeiro/dre`,
				`${baseUrl}/financeiro/cashflow`,
			],
			placeFeature: "backoffice",
			permissions: ["accessFinancialBackoffice"],
		},
		{
			icon: "zig-account",
			label: t("zigAccount"),
			url: `${baseUrl}/zig-conta`,
			hide: !isBetaZigConta,
		},
		{
			icon: "whats-up",
			label: t("whatsup"),
			url: `${baseUrl}/qual-boa`,
			placeFeature: "whatsUp",
		},
		{
			icon: "pos-machine",
			label: t("tagGroups"),
			url: `${baseUrl}/tag-groups`,
			placeFeature: "enableRechargeByZigCode",
		},
		{
			icon: "recharge-management",
			label: t("rechargeManagement"),
			url: `${baseUrl}/recharge-management`,
			permissions: ["manageRecharge"],
		},
		{
			icon: "config-adm",
			label: t("settings"),
			submenus: [
				{
					label: t("generalConfig"),
					url: `${baseUrl}/settings`,
				},
				{
					label: t("passwordCard"),
					url: `${baseUrl}/password-card`,
					permissions: ["managePasswordCardsEnterprise"],
				},
				{
					label: t("contracts"),
					url: `${baseUrl}/contracts`,
				},
				{
					label: t("audits"),
					url: `${baseUrl}/audits`,
				},
			],
		},
	];

	const items = showMenuV2
		? useNavigationWithRestriction<NavBarRouteV2>(routesV2)
		: useNavigationWithRestriction(routesV1);

	if (showMenuV2) {
		return (
			<NavBarV2
				items={items.restrictedRoutes as NavBarRouteV2[]}
				isOpenSandwichMenu={isOpenSandwichMenu}
				onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
				selectedMenu={currentSelectedMenu}
			/>
		);
	}

	return (
		<NavBar
			items={items.restrictedRoutes as NavBarRoute[]}
			isOpenSandwichMenu={isOpenSandwichMenu}
			onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
		/>
	);
}
const PlaceNavBar = React.memo(observer(Navbar));

export default PlaceNavBar;
