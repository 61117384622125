export const deliveryReport = {
	title: "Informes de Delivery",
	description: "Monitorea y analiza las entregas de delivery de tu establecimiento.",
	tabs: {
		general: "Informe general",
	},
	selectPeriod: "Selecciona un período",
	periodOptions: {
		today: "Hoy",
		last7days: "Últimos 7 días",
		last15days: "Últimos 15 días",
		last30days: "Últimos 30 días",
		last90days: "Últimos 90 días",
		custom: "Seleccionar un período",
	},
	generalTab: {
		export: "Exportar",
		filtersToolbar: {
			search: "Buscar n° de pedido",
			vendors: "Vendors",
			vendorsPlaceholder: "Seleccionar vendors",
			status: "Estado",
			statusPlaceholder: "Seleccionar estado",
			period: "Período",
			periodPlaceholder: "Seleccionar período",
			back: "Volver",
			apply: "Aplicar",
		},
		table: {
			columns: {
				vendor: "Vendor",
				reference: "Pedido N°",
				status: "Estado",
				date: "Fecha",
				orderCreatedAt: "Hora del Pedido",
				orderEndAt: "Pedido Finalizado",
				value: "Total",
				viewMore: "Ver Más",
			},
			summary: "Total del período",
			seeMore: "Ver más detalles",
		},
		vendorType: {
			rappi: "Rappi",
			coala: "Coala",
			ifood: "iFood",
			ubereats: "Ubereats",
		},
		orderStatus: {
			cancelled: "Cancelado",
			concluded: "Concluido",
			confirmed: "Confirmado",
			dispatched: "Despachado",
			integrated: "Integrado",
			pendingPayment: "Pago pendiente",
			pickupAreaAssigned: "Cliente en el local",
			placed: "Creado",
			ready: "Listo",
			scheduled: "Programado",
		},
		orderDetailsDrawer: {
			orderRange:
				"Pedido realizado el {{startDate}} a las {{startHour}}<br>Finalizado a las {{endHour}}",
			order: "Pedido",
			paymentDetails: "DETALLES FINANCIEROS",
			paymentMethod: "Forma de pago",
			subTotal: "Subtotal de la venta",
			discountAndImpost: "Cargos y descuentos",
			totalReceive: "Total a recibir",
			discountValue: "{{value}} - Cupón de descuento",
			discountValueDeliveryFee: "{{value}} - Cargo de Entrega",
			discountValueDeliveryFeeMarketplace: "{{value}} - Cargo de Entrega Marketplace",
			discountValueMarketplace: "{{value}} - Marketplace",
			paymentMethodType: {
				creditCardInPlace: "Tarjeta de crédito",
				creditCardOnline: "Tarjeta de crédito",
				debitCardOnline: "Tarjeta de débito",
				debitCardInPlace: "Tarjeta de débito",
				cash: "Efectivo",
				voucherInPlace: "Vale",
				ame: "Ame",
				pix: "Pix",
			},
		},
	},
	orderItemsTable: {
		columns: {
			count: "Cant.",
			name: "Artículo",
			baseUnitPrice: "P. Unitario",
			totalUnitPrice: "Subtotal",
		},
		summary: "Subtotal",
		totalReceive: "TOTAL A RECIBIR",
	},
};
