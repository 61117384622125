export const deliveryReport = {
	title: "Relatórios de Delivery",
	description: "Acompanhe e analise as entregas de delivery do seu estabelecimento.",
	tabs: {
		general: "Relatório geral",
	},
	selectPeriod: "Selecione um período",
	periodOptions: {
		today: "Hoje",
		last7days: "Últimos 7 dias",
		last15days: "Últimos 15 dias",
		last30days: "Últimos 30 dias",
		last90days: "Últimos 90 dias",
		custom: "Selecionar um período",
	},
	generalTab: {
		export: "Exportar",
		filtersToolbar: {
			search: "Buscar nº do pedido",
			vendors: "Vendors",
			vendorsPlaceholder: "Selecionar vendors",
			status: "Status",
			statusPlaceholder: "Selecionar status",
			period: "Período",
			periodPlaceholder: "Selecionar período",
			back: "Voltar",
			apply: "Aplicar",
		},
		table: {
			columns: {
				vendor: "Vendor",
				reference: "Pedido Nº",
				status: "Status",
				date: "Data",
				orderCreatedAt: "Hora do Pedido",
				orderEndAt: "Pedido Finalizado",
				value: "Total",
				viewMore: "Ver Mais",
			},
			summary: "Total do período",
			seeMore: "Ver mais detalhes",
		},
		vendorType: {
			rappi: "Rappi",
			coala: "Coala",
			ifood: "iFood",
			ubereats: "Ubereats",
		},
		orderStatus: {
			cancelled: "Cancelado",
			concluded: "Concluído",
			confirmed: "Confirmado",
			dispatched: "Despachado",
			integrated: "Integrado",
			pendingPayment: "Pagamento pendente",
			pickupAreaAssigned: "Cliente no local",
			placed: "Criado",
			ready: "Pronto",
			scheduled: "Agendado",
		},
		orderDetailsDrawer: {
			orderRange:
				"Pedido realizado em {{startDate}} às {{startHour}}<br>Finalizado às {{endHour}}",
			order: "Pedido",
			paymentDetails: "DETALHAMENTO FINANCEIRO",
			paymentMethod: "Forma de pagamento",
			subTotal: "Subtotal da venda",
			discountAndImpost: "Taxas e descontos",
			totalReceive: "Total a receber",
			discountValue: "{{value}} - Cupom de desconto",
			discountValueDeliveryFee: "{{value}} - Taxa de Entrega",
			discountValueDeliveryFeeMarketplace: "{{value}} - Taxa de Entrega Mercado",
			discountValueMarketplace: "{{value}} - Mercado",
			paymentMethodType: {
				creditCardInPlace: "Cartão de crédito",
				creditCardOnline: "Cartão de crédito",
				debitCardOnline: "Cartão de débito",
				debitCardInPlace: "Cartão de débito",
				cash: "Dinheiro",
				voucherInPlace: "Voucher",
				ame: "Ame",
				pix: "Pix",
			},
		},
	},
	orderItemsTable: {
		columns: {
			count: "Qtd.",
			name: "Item",
			baseUnitPrice: "V. Unitário",
			totalUnitPrice: "Subtotal",
		},
		summary: "Subtotal",
		totalReceive: "TOTAL A RECEBER",
	},
};
