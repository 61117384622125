import { importMenu } from "./import-menu";
import { deliveryMenus } from "./menus";
import { deliverySettings } from "./settings";
import { syncMenu } from "./sync-menu";
import { deliveryReport } from "./report";

export const delivery = {
	goBack: "Voltar",
	updateManyExternalIntegrations: {
		success: "Integrações atualizadas com sucesso",
	},
	externalStoreIntegrationStatus: {
		synced: "Sincronizado",
		pendingSyncing: "Sincronização pendente",
		pendingImporting: "Importação pendente",
	},
	deliverySettings,
	syncMenu,
	importMenu,
	deliveryMenus,
	deliveryReport,
};
