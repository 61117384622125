import { importMenu } from "./import-menu";
import { deliverySettings } from "./settings";
import { syncMenu } from "./sync-menu";
import { deliveryMenus } from "./menus";
import { deliveryReport } from "./report";

export const delivery = {
	goBack: "Retour",
	updateManyExternalIntegrations: {
		success: "Integrations mises jour avec succs",
	},
	externalStoreIntegrationStatus: {
		synced: "Synchronisé",
		pendingSyncing: "Synchronisation en attente",
		pendingImporting: "Importation en attente",
	},
	deliverySettings,
	syncMenu,
	importMenu,
	deliveryMenus,
	deliveryReport,
};
